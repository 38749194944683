import React from "react"
import PropTypes from "prop-types"
import BasicPreviewAnswer from '../basic_preview_answer/BasicPreviewAnswer'
import RadioPreviewAnswer from '../radio_preview_answer/RadioPreviewAnswer'
import CheckBoxPreviewAnswer from '../checkbox_preview_answer/CheckBoxPreviewAnswer'
import _ from 'lodash'

import SignatureAnswer from '../signature_answer/SignatureAnswer'
import ParagraphPreviewAnswer from '../paragraph_preview_answer/ParagraphPreviewAnswer'
import LinearScalePreviewAnswer from "../linear_scale_preview_answer/LinearScalePreviewAnswer";
import SelectPreviewAnswer from '../select_preview_answer/SelectPreviewAnswer'
import PhotoPreviewAnswer from "../photo_preview_answer/PhotoPreviewAnswer";
import DefaultPreviewAnswer from "../default_preview_answer/DefaultPreviewAnswer";

class QuestionPreview extends React.Component {
  
  isQuestionWithLogicItems(kind) {
    return kind == 'radio' || 
      kind == 'select_dropdown'
  }


  renderForm() {
    const { question, index, onVoiceRecognitionEnd, voiceRecognitionStart, 
      voiceRecognitionStop, onVoiceRecognitionResultEnd, voiceRecognitionStarted,
      voiceRecognitionStopped, isFormSubmitted, handleBasicAnswerInput, handleRadioBtnChange, handlePreviewSelect , handleCheckboxBtnChange, handleLinearScalePreviewChange} = this.props

      switch (question.kind) {
        case 'radio':
          return <RadioPreviewAnswer 
            key={index} 
            questionIndex={index}
            handleRadioBtnChange={handleRadioBtnChange}
            options={question.options} 
            id={question.id}
            title={question.title}
            type={question.kind}
            required={question.required}
          />
          break
        case 'checkbox':
          return <CheckBoxPreviewAnswer 
            key={index} 
            questionIndex={index}
            id={question.id}
            handleCheckboxBtnChange={handleCheckboxBtnChange}
            options={question.options} 
            title={question.title}
            type={question.kind}
            required={question.required}
          />
          break
        case 'signature':
          return <SignatureAnswer 
            key={index} 
            title={question.title} 
            required={question.required}
          />
          break
        case 'paragraph':
          return <ParagraphPreviewAnswer 
            key={index} 
            index={index}
            id={question.id}
            title={question.title} 
            required={question.required}
            answer={question.answer}
            onVoiceRecognitionEnd={onVoiceRecognitionEnd}
            voiceRecognitionStart={voiceRecognitionStart}
            voiceRecognitionStop={voiceRecognitionStop}
            onVoiceRecognitionResultEnd={onVoiceRecognitionResultEnd}
            voiceRecognitionStarted={question.voiceRecognitionStarted}
            voiceRecognitionStopped={question.voiceRecognitionStopped}
            handleBasicAnswerInput={handleBasicAnswerInput}
          />
          break
        case 'linear_scale':
          return <LinearScalePreviewAnswer 
            key={index} 
            title={question.title} 
            linearScaleData={question.linearScaleData}
            required={question.required}
            question={question}
            handleLinearScalePreviewChange={handleLinearScalePreviewChange}
          />
          break
        case 'select_dropdown':
          return <SelectPreviewAnswer 
            key={index} 
            options={question.options} 
            answer={question.answer}
            title={question.title}
            type={question.kind}
            questionIndex={index}
            id={question.id}
            handlePreviewSelect={handlePreviewSelect}
            required={question.required}
          />
          break

        case 'picture':
          return <PhotoPreviewAnswer 
            key={index} 
            answers={question.answers} 
            title={question.title}
            required={question.required}
          />
          break
        case 'default_answers':
          return <DefaultPreviewAnswer 
            key={index} 
            answer={'s'} 
            title={question.title}
            required={question.required}
          />
          break

          default: 
          return <BasicPreviewAnswer 
            key={index} 
            index={index}
            type={question.kind} 
            id={question.id}
            answer={question.answer}
            isFormSubmitted={isFormSubmitted}
            handleBasicAnswerInput={handleBasicAnswerInput}
            title={question.title} 
            required={question.required}
            onVoiceRecognitionEnd={onVoiceRecognitionEnd}
            voiceRecognitionStart={voiceRecognitionStart}
            voiceRecognitionStop={voiceRecognitionStop}
            onVoiceRecognitionResultEnd={onVoiceRecognitionResultEnd}
            voiceRecognitionStarted={question.voiceRecognitionStarted}
            voiceRecognitionStopped={question.voiceRecognitionStopped}
          />
      }
  }


  render () {

    return (
      <div 
        className="question-preview" 
        style={{
          borderBottom: '1px solid #e3e3e3',
          margin: '20px 0'
        }}
      >
        {this.renderForm() }
    </div>
    );
  }
}

export default QuestionPreview
