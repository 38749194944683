import React from "react"
import PropTypes from "prop-types"
import BasicAnswer from "../basic_answer/BasicAnswer";
import MultipleAnswer from "../multiple_answer/MultipleAnswer";
import ParagraphAnswer from "../paragraph_answer/ParagraphAnswer";
import LinearScaleAnswer from "../linear_scale_answer/LinearScaleAnswer";
import DefaultAnswers from "../default_answers/DefaultAnswers"

import {capitalize, iconName } from '../../../helper'

import './question.scss'

const answerTypes = ['text', 'paragraph','number','date', 'linear_scale',
  'radio', 'checkbox', 'select_dropdown', 'file', 'picture', 'signature']

class Question extends React.Component {
  
  renderAnswer () {
    const {question, index, addMultipleAnswer, removeMultipleAnswer, changeMultipleAnswerValue, handleLinearScaleChange, handleLinearScaleLabelChange, addLogic, handleAddLogicBtn, showAnswerTemplateModal , handleLinearScaleAddLogicChange, addLogicOnLinearScaleClick, subQuestion} = this.props

    switch(question.kind) {
      case 'paragraph':
        return <ParagraphAnswer />
        break
      case 'linear_scale':
        return <LinearScaleAnswer 
          linearScaleData={question.linearScaleData} 
          addLogicOnLinearScaleClick={addLogicOnLinearScaleClick}
          index={index} 
          questionId={question.id}
          handleLinearScaleChange={handleLinearScaleChange}
          handleLinearScaleLabelChange={handleLinearScaleLabelChange}
          handleLinearScaleAddLogicChange={handleLinearScaleAddLogicChange}
          />
        break
      case 'radio':
        return <MultipleAnswer 
          key={index} 
          index={index}
          questionId={question.id} 
          kind={question.kind}
          subQuestion={subQuestion}
          addMultipleAnswer={addMultipleAnswer} 
          options={question.options}
          removeMultipleAnswer={removeMultipleAnswer}
          changeMultipleAnswerValue={changeMultipleAnswerValue}
          handleAddLogicBtn={handleAddLogicBtn}
          addLogic={addLogic}
          showAnswerTemplateModal={showAnswerTemplateModal}
          />
        break

      case 'select_dropdown':
        return <MultipleAnswer 
          key={index} 
          index={index} 
          questionId={question.id} 
          options={question.options}
          subQuestion={subQuestion}
          kind={question.kind}
          addMultipleAnswer={addMultipleAnswer} 
          removeMultipleAnswer={removeMultipleAnswer}
          changeMultipleAnswerValue={changeMultipleAnswerValue}
          handleAddLogicBtn={handleAddLogicBtn}
          addLogic={addLogic}
          showAnswerTemplateModal={showAnswerTemplateModal}
        />
        break
      case 'checkbox':
        return <MultipleAnswer 
          key={index} 
          index={index} 
          questionId={question.id} 
          kind={question.kind}
          subQuestion={subQuestion}
          addMultipleAnswer={addMultipleAnswer} 
          options={question.options}
          removeMultipleAnswer={removeMultipleAnswer}
          changeMultipleAnswerValue={changeMultipleAnswerValue}
          handleAddLogicBtn={handleAddLogicBtn}
          addLogic={addLogic}
        />
        break
    

      default:
        return <BasicAnswer kind={question.kind} />
    }
  }

  render () {
    const { question, index, toggleisRequired, subQuestion, textValue,
      handleAnswerTypeDropdown, handleQuestionTitleChange , removeQuestion} = this.props

    const indentation = subQuestion ? 30: 0

    const dropDownTitle = question.kind ? capitalize(question.kind) : 'Answer Type'
    
    return (
      <div className="question" style={{marginLeft: indentation}}>
        <span 
          className="question-remove-icon glyphicon glyphicon-remove"
          onClick={()=> removeQuestion(question.id)}
        >
        </span>
        { !subQuestion && 
          (<span className="question-draggable-icon glyphicon glyphicon-align-justify"></span>)
        }
        <div className="question-header-top">
        { subQuestion ? (
          <div>
            <h5 style={{color: 'orange'}}> If {textValue} is selected</h5>
            <h4> Question </h4>
          </div>
            ): (
            <h4> Question {index+1}</h4>
            )
          }
         
          <div className="checkbox">
            <label>
              <input type="checkbox" checked={question.required} onChange={(e)=> toggleisRequired(question.id, index)}/>
              Required
            </label>
          </div>
        </div>
        <div className="question-header">
          <div className="row">
          
            <div className="col-xs-12 col-sm-7">
              <input 
                type="text" 
                className="form-control question-input" 
                placeholder="Enter Title"
                value={question.title || ''}
                onChange={(e)=> handleQuestionTitleChange(e.target.value, question.id)}
              />
            </div>
            <div className="col-xs-12 col-sm-5">
              <div className="btn-group answer-type-dropdown-wrapper ">
                <button type="button" className="btn btn-default dropdown-toggle answer-type-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <span> {dropDownTitle}</span>
                  <span className="caret"></span>
                </button>

                <ul className="dropdown-menu question-dropdown-menu js-dropdown-btn">
                  {
                    answerTypes.map((type, i) => {
                      return <li key={i} onClick={()=> handleAnswerTypeDropdown(question.id, type)}>
                        <a>
                          <span className={`glyphicon ${iconName(type)}`} aria-hidden="true"></span>
                          <span>{capitalize(type)}</span>
                        </a>
                      </li>
                    })
                  }
                </ul>
              </div>
            </div>
          </div>
        
        </div>
        
        {
          question.kind? (
            <div className="question-body">
               { this.renderAnswer() }
            </div>
          ): null
        }


      </div>
    );
  }
}

export default Question
