import React from "react"
import PropTypes from "prop-types"
import { VoiceRecognition } from 'react-voice-components'
import is from 'is_js'


class VoiceToText extends React.Component {
  render () {
    if (!is.chrome()) return null

    
    const { id, onVoiceRecognitionEnd, onVoiceRecognitionResultEnd, 
      voiceRecognitionStart, voiceRecognitionStop, voiceRecognitionStarted, voiceRecognitionStopped  } = this.props
    const style = {
      borderRadius: '4px',
      fontSize: '17px',
      padding: '8px 8px 6px'
    }

    return (
      <div className="voice-recognition-wrapper">
        
        {voiceRecognitionStarted? (
          <button 
            className="btn btn-danger glyphicon glyphicon-record white-color" 
            style={style}
            onClick={() => voiceRecognitionStop(id)}>
          </button>
        ):(
          <button 
            className="btn btn-default glyphicon glyphicon-record" 
            style={style}
            onClick={() => voiceRecognitionStart(id)}>
          </button>
        )}
        
        {
          voiceRecognitionStarted && (
            <VoiceRecognition
             onStart={(e)=> console.log('started')}
             onEnd={()=> onVoiceRecognitionEnd(id)}
             onResult={({finalTranscript})=> onVoiceRecognitionResultEnd(id, finalTranscript)}
             onError={(e)=> console.log(e)}
             continuous={true}
             lang="en-US"
             stop={voiceRecognitionStopped} 
            />
          )
        }

      </div>
    );
  }
}

export default VoiceToText
