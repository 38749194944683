import React from "react"
import PropTypes from "prop-types"
import './photo_preview_answer.scss'
import {capitalize, showRequired} from '../../../helper'
import ImageUploader from 'react-images-upload';

class PhotoPreviewAnswer extends React.Component {
  constructor(props) {
		super(props);
		 this.state = { pictures: [] };
		 this.onDrop = this.onDrop.bind(this);
	}

	onDrop(pictureFiles, pictureDataURLs) {
		this.setState({
        pictures: this.state.pictures.concat(pictureFiles),
    });
	}

  isTexteble() {
    return this.props.type == 'text'
  }

  isEmpty() {
    return this.state.pictures.length < 1
  }

  render () {
    const { title, answer, required } = this.props
    const hasError = required && this.isEmpty() ? 'has-error': ''

    return (
      <div className={`photo-preview-answer ${hasError}`} style={{margin: '20px 0'}}>
        
        <div className="photo-preview-answer-title-section">
          <label className="control-label"> 
          {title} 
          {required && <strong className="red-color">*</strong>}
          </label>
        </div>

        <ImageUploader
          withIcon={true}
          buttonText='Choose image'
          buttonStyles={{
            padding: '12px 23px',
            borderRadius: 0,
            background: '#9E9E9E'
          }}
          label={'Accepted: jpg, png, jpeg'}
          withPreview={true}
          onChange={this.onDrop}
          imgExtension={['jpg', '.png', '.jpeg']}
          maxFileSize={115242880}
          errorStyle={{color: 'rgb(169, 68, 66)'}}
        />
      {hasError && <span className="error-message validation-error"> This Field Can't Be Blank </span> }

      
      </div>
    );
  }
}

export default PhotoPreviewAnswer
