export function capitalize (string) {
  const toUpperCase = string.charAt(0).toUpperCase() + string.slice(1);
  return toUpperCase.replace('_', ' ')
}

export function getRandomNumber() {
  return Math.floor(Math.random() * 1549384) + 1  
}

export function iconName(type) {
  let iconName = ''

  switch (type) {
    case 'date':
      iconName = 'glyphicon-time'
      break
    case 'number':
      iconName = 'glyphicon-signal'
      break
    case 'paragraph':
      iconName = 'glyphicon-text-height'
      break
    case 'linear-scale':
      iconName = 'glyphicon-signal'
      break
    case 'file':
      iconName = 'glyphicon-cloud'
      break
    case 'picture':
      iconName = 'glyphicon-picture'
      break
    case 'checkbox':
      iconName = 'glyphicon-check'
      break
    case 'select':
      iconName = 'glyphicon-th-list'
      break
    case 'signature':
      iconName = 'glyphicon-pencil'
      break
    case 'radio':
      iconName = 'glyphicon-ok-circle'
      break
    default:
      iconName = 'glyphicon-font'
  }

  

  return iconName
}

export function range(start, end, step) {
  var _end = end || start;
  var _start = end ? start : 0;
  var _step = step || 1;
  return Array((_end - _start) / _step).fill(0).map((v, i) => _start + (i * _step));
}

export function isChrome () {
  return !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)
}
