import React from 'react';
import SortableCustomFieldList from '../SortableCustomFieldList';

class FieldsBox extends React.Component {

  getImageForCanvas(e) {
    const { addCustomImage } = this.props;

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onload = () => {
      if (file) {
        var img = new Image();

        img.src = window.URL.createObjectURL(file);
        img.onload = () => {
          const ratio = img.naturalWidth / img.naturalHeight

          window.URL.revokeObjectURL(img.src);
          addCustomImage(reader.result, ratio, img.naturalWidth, img.naturalHeight);
        }
      }
    }

    reader.readAsDataURL(file);
  }

  isCustomSignature(signatureKind) {
    return (signatureKind == "employer_signature")
      || (signatureKind == "trainee_signature");
  }

  render() {
    const {
      addCustomSignature,
      addSignature,
      addCustomField,
      addText,
      customFields,
      defaultFields,
      handleCustomFieldNameChange,
      isCustomFieldNameTaken,
      removeCustomFieldItem,
      onSortEnd,
    } = this.props;

    return (
      <div className="dropdown basic-controls-item default-answers basic-controls-dropdown">

        <a
          type="button"
          className="dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="true"
        >
          <span className="glyphicon glyphicon-th-large"></span>
        </a>

        <ul className="js-fields-dropdown dropdown-menu animated bounceIn myBounce" aria-labelledby="dropdownMenu1">

          <div className="custom-fields-form">
            <h4 className="default-answer-sub-title">Add New Field</h4>

            <input
              className="form-control custom-field-text-input"
              type="text"
              placeholder="Type Field Name"
              onChange={(e) => handleCustomFieldNameChange(e.target.value)}
            />
            {
              isCustomFieldNameTaken && (
                <div className="alert alert-danger" role="alert">
                  <strong>Custom Field Names</strong> Must Be Unique!
                </div>

              )
            }


            <div className="custom-field-type-picker-wrapper">

              <div className="btn-group btn-group-lg" role="group" aria-label="...">
                <button
                  className="btn btn-default"
                  onClick={(e) => {
                    e.preventDefault();
                    addCustomField(true, 'text')
                  }}
                >
                  <span className="glyphicon glyphicon-font"></span>
                  <span>Text</span>
                </button>
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={() => addCustomField(true, 'dropdown')}
                >
                  <span className="glyphicon glyphicon-list-alt"></span>
                  <span>Dropdown</span>
                </button>
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={() => addCustomField(true, 'checkbox')}
                >
                  <span className="glyphicon glyphicon-ok-circle"></span>
                  <span>Checkbox</span>
                </button>
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={() => addCustomField(true, 'image')}
                >
                  <span className="glyphicon glyphicon-picture"></span>
                  <span>Image</span>
                </button>
              </div>
            </div>
          </div>

          <div className="custom-fields">
            <h4 className="default-answer-sub-title">Custom Fields</h4>
            <div className="custom-fields-list">
              {
                customFields.length > 0 ? (
                  <SortableCustomFieldList
                    items={customFields}
                    onSortEnd={onSortEnd}
                    removeCustomFieldItem={removeCustomFieldItem}
                    addCustomField={addCustomField}
                  />
                ) : (
                    <span style={{
                      display: 'block',
                      textAlign: 'center'
                    }}> No Custom Fields </span>
                  )
              }

            </div>

          </div>

          <div className="divider" />
          <h4 className="default-answer-sub-title">Default Fields</h4>

          <div className="default-fields">
            {
              defaultFields.map((field, index) => {

                if (field.type == 'signature') {
                  if (this.isCustomSignature(field.signatureType)) {
                    return (
                      <li
                        key={index}
                        onClick={() => addCustomSignature(field.signatureType)}>
                        <a>
                          <span>
                            <span className={field.icon}></span>
                            {field.name}
                          </span>
                          <span className="glyphicon glyphicon-ok check"></span>
                        </a>
                      </li>
                    )
                  }

                  return (
                    <li key={index} onClick={() => addSignature(field.name, true)}>
                      <a>
                        <span>
                          <span className={field.icon}></span>
                          {field.name}
                        </span>
                        <span className="glyphicon glyphicon-ok check"></span>
                      </a>
                    </li>
                  )
                }

                const isDateField = field.type == 'date' ? true : false

                return (
                  <li key={index} onClick={() => addText(field.name, true, null, isDateField, field.value)}>
                    <a>
                      <span>
                        <span className={field.icon}></span>
                        {field.name}
                      </span>
                      <span className="glyphicon glyphicon-ok check"></span>
                    </a>
                  </li>
                )
              })
            }
          </div>
        </ul>
      </div>
    )
  }
}

export default FieldsBox;

// <button
//   type="button"
//   className="btn btn-default"
//   onClick={() => addCustomField(true, 'separator')}
// >
//   <span className="glyphicon glyphicon-minus"></span>
//   <span>Separator</span>
// </button>
