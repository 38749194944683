import React from "react"
import PropTypes from "prop-types"
import SignaturePad from 'react-signature-pad-wrapper'
import SignatureCanvas from 'react-signature-canvas'

import './signature_answer.scss'

class SignatureAnswer extends React.Component {
  state = {
    isEmpty: true
  }

  componentDidMount() {
    this.setState({isEmpty: this.sigCanvas.isEmpty()})
  }
  onEnd(e) {
    this.setState({isEmpty: this.sigCanvas.isEmpty()})
  }

  onClear() {
    this.sigCanvas.clear()
    this.setState({isEmpty: true})
  }

  render () {
    const {title, required} = this.props
    const hasError = required && this.state.isEmpty ? 'has-error': ''
    return (
      <div className={`signature ${hasError}`} style={{margin: '20px 0'}} >
        <label className="control-label"> 
        {title}
        {required && <strong className="red-color">*</strong>}
         </label>
        <div className="signature-box">
          <a onClick={this.onClear.bind(this)} className="label label-danger js-clear-canvas">CLEAR</a>
          <SignatureCanvas 
            ref={(ref) => { this.sigCanvas = ref }}
            canvasProps={{width: '400', height: '250', className: 'sigCanvas'}} 
            onEnd={(e)=> this.onEnd(e)}
          />
        </div>
        {hasError && <span className="error-message validation-error"> This Field Can't Be Blank </span> }

      </div>
    );
  }
}

export default SignatureAnswer
