import React from "react"
import PropTypes from "prop-types"
import './checkbox.scss'

class Checkbox extends React.Component {
  render () {

    const {number, answer, removeMultipleAnswer, questionIndex, 
      changeMultipleAnswerValue, id} = this.props

    return (
      <div className="checkbox-wrapper row">
        <div className="col-xs-7 checkbox-input-wrapper">
          <span> {number+1}. </span>
          <input 
            type="text" 
            className="form-control checkbox-input-field" 
            placeholder="Enter Answer" 
            value={answer || ''}
            onChange={(e)=> changeMultipleAnswerValue(e.target.value, questionIndex, id)}
          />
        </div>
        <div className="col-xs-4 checkbox-btn-group">
          <span 
            onClick={()=> addLogic(questionIndex, id)} 
            className="label label-info">
            ADD LOGIC
          </span>
          <span onClick={()=> removeMultipleAnswer(questionIndex, id)} className="label label-danger">REMOVE</span>
        </div>
      </div>
    );
  }
}

export default Checkbox
