import React from "react"
import PropTypes from "prop-types"
import FormBuilderEditor from './form_builder_editor/FormBuilderEditor'
import FormBuilderPreview from './form_builder_preview/FormBuilderPreview'
import axios from 'axios'
import _ from 'lodash'
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'

import {getRandomNumber, range} from '../helper'
import {normalizeData} from "./form_builder_helper"
import * as Scroll from 'react-scroll';
import { Link, Element , Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

import './form_builder.scss'
const answerTemplates = [
  [
    {
     value: 'Compliant',
     label: 'label-success',
     id: Date.now(),
     checked: false,
     logic_items: []
    },
    {
      value: 'Non-Compliant',
      label: 'label-danger',
      id: Date.now() + 1,
      checked: false,
     logic_items: []

     },
     {
      value: 'N/A',
      label: 'label-default',
      id: Date.now() + 2,
      checked: false,
     logic_items: []


     }
    ],
    [
    {
      value: 'Good',
      label: 'label-success',
      id: Date.now() ,
      checked: false,
     logic_items: []


    },
    {
      value: 'Fair',
      label: 'label-warning',
      id: Date.now() +1,
      checked: false,
     logic_items: []


      },
      {
      value: 'Poor',
      label: 'label-danger',
      id: Date.now() +2,
      checked: false,
     logic_items: []


      },
      {
      value: 'N/A',
      label: 'label-default',
      id: Date.now() +3,
      checked: false,
     logic_items: []


      }
    ],
    [
      {
        value: 'Safe',
        label: 'label-success',
        id: Date.now() ,
        checked: false,
     logic_items: []

      },
      {
        value: 'At-Risk',
        label: 'label-danger',
        id: Date.now() +1,
        checked: false,
     logic_items: []


        },
        {
        value: 'N/A',
        label: 'label-default',
        id: Date.now() +2,
        checked: false,
     logic_items: []


        }
      ],
      [
        {
          value: 'Pass',
          label: 'label-success',
          id: Date.now() ,
          checked: false,
     logic_items: []


        },
        {
          value: 'Fail',
          label: 'label-danger',
          id: Date.now() +1,
          checked: false,
     logic_items: []


          },
          {
          value: 'N/A',
          label: 'label-default',
          id: Date.now() +2,
          checked: false,
     logic_items: []

          }
        ],
        [
          {
            value: 'Yes',
            label: 'label-success',
            id: Date.now(),
            checked: false,
     logic_items: []
          },
          {
            value: 'No',
            label: 'label-danger',
            id: Date.now() +1,
            checked: false,
              logic_items: []
            },
            {
            value: 'N/A',
            label: 'label-default',
            id: Date.now() +2,
            checked: false,
            logic_items: []
            }
          ],
]

class FormBuilder extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
      questions: [],
      rawQuestions: [],
      title: '',
      modalShow: false,
      currentQuestionId: null,
      questionIdsForOrder: []
    }

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.setState({ modalShow: false });
  }

  handleShow(questionId) {
    const newQuestions = this.state.questions.filter(q=> q.id != questionId)

    this.setState({ modalShow: true, modalQuestions: newQuestions });
  }

  componentDidMount() {
    axios.get(`/digital_forms/${this.props.digitalFormId}`)
      .then(this.normalizeBaseData.bind(this))
      .catch(console.error)

      Events.scrollEvent.register('begin', function(to, element) {
        console.log("begin", arguments);
      });
  
      Events.scrollEvent.register('end', function(to, element) {
        console.log("end", arguments);
      });
  
      scrollSpy.update();
  }


  componentWillUnmount() {
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
  }

  scrollMore(number) {
    scroll.scrollMore(number);
  }


  normalizeData() {
    const questions = this.state.rawQuestions.filter(q=> !q.parent_question_id)
    
    

    const newQuestions = questions.map(q=> {
      const logicItems = []

      q.options && q.options.forEach(o => {
        o.logic_items && o.logic_items.forEach(l=> {
            logicItems.push(l.question_id)
        })
      })
      
      if (q.kind == "linear_scale") {
        q.linearScaleData.options && q.linearScaleData.options.forEach(o => {
          o.logic_items && o.logic_items.forEach(l=> {
            logicItems.push(l.question_id)
          })
        })
      }

      return {
        ...q,
        subQuestions: logicItems.map(item => {
          return _.find(this.state.rawQuestions, (q)=> q.id == item )
        })
      }
      
    })

    this.setState({
      questions: newQuestions
    })
  }

  getNewQuestions() {
    const questions = this.state.rawQuestions.filter(q=> !q.parent_question_id)
  
    const newQuestions = questions.map(q=> {
      const logicItems = []
      
      q.additional_data.options && q.additional_data.options.forEach(o => {
        o.logic_items && o.logic_items.forEach(l=> {
            logicItems.push(l.question_id)
        })
      })
  
        return {
        ...q,
        subQuestions: logicItems.map(item => {
          return _.find(this.state.rawQuestions, (q)=> q.id == item )
        })
      }
      
    })

    return newQuestions
  }

  normalizeQuestions(questions) {
    return questions.map((question)=> {
      if (this.isQuestionWithMultipleAnswer(question.kind)) {
        return {
          ...question,
          options: this.normalizeOptions(question.additional_data.options),
        }
      } else if (question.kind == 'linear_scale') {
        return {
          ...question,
          linearScaleData: this.normalizeLinearScaleData(question.additional_data.linear_scale_data)
        }
      } else if (question.kind == 'default_answers') {
        return {
          ...question,
          defaultAnswers: this.normalizeLinearScaleData(question.additional_data.linear_scale_data)
        }
      }
      return {...question}
    })
  }

  normalizeBaseData({data}) {
    console.log("from server ", data)
    const questions = normalizeData(data)

    this.setState({
      title: data.title,
      rawQuestions: this.normalizeQuestions(data.questions)
    }, ()=> {
      this.normalizeData()
    })
  }

  

normalizeLinearScaleData(linearScaleData) {
  if (_.isEmpty(linearScaleData)) {
    return {
      from: {
        value: 0, 
        label: ''
      }, 
      to: {
        value: 2,
        label: ''
      },
      currentLogicItemValue: 1,
      options: this.generateLinearScaleDataOptions(0,2)
    }
  }

  return linearScaleData
}

  normalizeOptions(options) {
    if (_.isEmpty(options)) {
      return [{
        id: Date.now(),
        value: '',
        logic_items: []
      }] 
    }

    return options
  }


  isQuestionWithMultipleAnswer(kind) {
    return kind == 'checkbox' || 
      kind == 'radio' || 
      kind == 'select_dropdown'
  }


  handleDigitalFormTitleChange(title) {
      // if (title) {
        this.setState(
          {title},
          ()=> {
            axios.put(`/digital_forms/${this.props.digitalFormId}`, {title})
              .then((response)=> console.log('sds'))
              .catch(function (error) {console.log(error);
            })
          }
        )
      // }
  }

  

  addQuestion() {
    axios.post(`/questions`, {
      'digital_form_id': this.props.digitalFormId
    })
    .then((response)=> {
      this.setState({
        rawQuestions: this.state.rawQuestions.concat(response.data)
      }, ()=> {
        this.scrollMore(250)
        this.normalizeData()
      })
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  handleAnswerTypeDropdown (questionId, kind) {
    const question = this.state.rawQuestions.find(q => q.id === questionId).kind;

    this.scrollMore(250)

    const linearScaleData = {
      from: {
        value: 0, 
        label: ''
      }, 
      to: {
        value: 2,
        label: ''
      },
      currentLogicItemValue: 1,
      options: this.generateLinearScaleDataOptions(0,2)
    }

    let payload = {}

    if(kind == "linear_scale") {
      payload = {
        kind,
        additional_data: {
          linear_scale_data: linearScaleData
        }
      }
    } else {
      payload = {
        kind,
      }
    }

    if (question == kind) return 


    axios.put(`/questions/${questionId}`, payload)
    .then((response)=> {
      const newQuestions = this.state.rawQuestions.map((question,i)=> {
        if (questionId == question.id) {
          if (this.isQuestionWithMultipleAnswer(kind)) {
            return {
              ...question,
              kind: response.data.kind,
              options: this.normalizeOptions(response.data.additional_data.options)
            }
          }
  
          else if (kind == 'linear_scale') {
            const linearScaleData = response.data.additional_data.linear_scale_data
            return {
              ...question,
              kind: response.data.kind,
              linearScaleData: {
                from: {
                  value: linearScaleData.from.value, 
                  label: linearScaleData.from.label
                }, 
                to: {
                  value: linearScaleData.to.value,
                  label: linearScaleData.to.label
                },
                options: linearScaleData.options
              }
            }
          }
  
          return {
            ...question,
            kind: response.data.kind,
          }
        }
        return {...question}
      })
  
      this.setState({rawQuestions: newQuestions}, ()=> {
        this.normalizeData()
        this.scrollMore(100)
      })
    })
    .catch(function (error) {
      console.log(error);
    });


  }

  addMultipleAnswer(questionId) {
    const newQuestions = [...this.state.rawQuestions]

    this.setState({
      rawQuestions: this.state.rawQuestions.map(question=> {
        if(question.id == questionId) {
          return {
            ...question,
            options: question.options.concat({ id: Date.now(), value: '', checked: false, logic_items: []})
          }
        }
        return {...question}
      })
    }, ()=> {
      this.normalizeData()
    })
  }

  removeMultipleAnswer(questionId, answerId) {
    const newQuestions = this.state.rawQuestions.map(question=> {
      if (question.id == questionId) {
        return {
          ...question,
          options: question.options.filter(option=> option.id !== answerId)
        }
      }
      return {...question}
    })

    this.setState({rawQuestions: newQuestions}, ()=> {
      const options = this.state.rawQuestions
          .filter(q=> q.id == questionId)[0].options
          axios.put(`/questions/${questionId}`, {
            additional_data: {
              options
            }
          })
          .then((response)=> this.normalizeData())
          .catch((error)=> console.log(error))
        })
  }

  changeMultipleAnswerValue(value, questionId, optionId) {
    this.setState({
      rawQuestions: this.state.rawQuestions
        .map(question=> {
          if (question.id == questionId) {
            return {
              ...question,
              options: question.options.map(option=> {
                if (option.id == optionId) {
                  return {
                    ...option,
                    value
                  }
                }
                return {...option}
              })
            }
          }
          return {...question}
        })},
        ()=> {
          const options = this.state.rawQuestions
          .filter(q=> q.id == questionId)[0].options
          axios.put(`/questions/${questionId}`, {
            additional_data: {
              options
            }
          })
          .then((response)=> this.normalizeData())
          .catch((error)=> console.log(error))
        })
  }


  handleQuestionTitleChange(title, questionId, isSubQuestion) {
      this.setState({
        rawQuestions: this.state.rawQuestions.map((question, i) => {
          if (question.id == questionId) {
            return {
              ...question,
              title
            }
          }
          return {...question}
        })
      },
      ()=> {
        this.normalizeData()
        axios.put(`/questions/${questionId}`, {
          title
        })
        .then((response)=> {
          // console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
      })
  }

  toggleisRequired(questionId, index) {
    const isRequired = _.find(this.state.rawQuestions, (q)=> q.id == questionId ).required
    axios.put(`/questions/${questionId}`, {
      required: !isRequired
    })
    .then((response)=> {
      this.setState({
        rawQuestions: this.state.rawQuestions.map((question, i)=> {
          if(question.id == questionId) {
            return {
              ...question,
              required: response.data.required
            }
          }
          return {...question}
        })
      }, ()=> this.normalizeData())
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  generateLinearScaleDataOptions(from, to) {
    const options = range(parseInt(from, 10), parseInt(to, 10)+1).map(value=> {
      return {
        id: Date.now() + getRandomNumber(),
        logic_items: [],
        value
      }
    })

    return options
  }

  handleLinearScaleChange(questionId, key, value) {
    const newQuestions = [...this.state.rawQuestions]
    
    const newQuestion = newQuestions.find(q=> q.id == questionId)
    
    newQuestions.find(q=> q.id == questionId).linearScaleData[key].value = value
    newQuestions.find(q=> q.id == questionId).linearScaleData.options = this.generateLinearScaleDataOptions(newQuestion.linearScaleData.from.value,newQuestion.linearScaleData.to.value)
    
    this.setState(
      {rawQuestions: newQuestions},
      ()=> {
        this.normalizeData()
        const linearScaleData = this.state.rawQuestions
        .filter(q=> q.id == questionId)[0].linearScaleData
        axios.put(`/questions/${questionId}`, {
          additional_data: {
            'linear_scale_data': linearScaleData
          }
        })
        .then((response)=> {
          // console.log("resss", response)
        })
        .catch((error)=> console.log(error))
      })
  }

  handleLinearScaleLabelChange(questionId, key, value) {
    let newQuestions = [...this.state.rawQuestions]
    newQuestions.find(q=> q.id == questionId).linearScaleData[key].label = value
    
    this.setState(
      {rawQuestions: newQuestions},
      ()=> {
        this.normalizeData()
        const linearScaleData = this.state.rawQuestions
        .filter(q=> q.id == questionId)[0].linearScaleData
        axios.put(`/questions/${questionId}`, {
          additional_data: {
            'linear_scale_data': linearScaleData
          }
        })
        .then((response)=> {

        })
        .catch((error)=> console.log(error))
      }
      )
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }



    const questions = this.reorder(
      this.state.rawQuestions,
      result.source.index,
      result.destination.index
    );

    console.log(result.destination.index)
    console.log(result.source.index)

    const questionIds = []

    this.setState({
      
      rawQuestions: questions.map((question, index)=> {
        return {
          ...question,
          order: index+1
        }
      })
    }, ()=> {
      console.log("test" , questionIds)
      axios.put(`/questions/bulk_update`, {
        questions: this.state.rawQuestions
      })
      .then((response)=> {
        console.log(response.data)
      })
      .catch((error)=> console.log(error))
      this.normalizeData()
    });
  }

   reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  }


 

  onVoiceRecognitionEnd(questionId) {

    this.setState({rawQuestions: this.state.rawQuestions.map(question=> {
      if(question.id == questionId) {
        return {
          ...question,
          voiceRecognitionStarted: false,
          voiceRecognitionStopped: false,
        }
      }
      return {...question}
    })}, ()=> {
      this.normalizeData()
    })
    
  }

  voiceRecognitionStop(questionId) {

    this.setState({rawQuestions: this.state.rawQuestions.map(question=> {
      if(question.id == questionId) {
        return {
          ...question,
          voiceRecognitionStopped: true
        }
      }
      return {...question}
    })}, ()=> {
      this.normalizeData()
    })
  }

  voiceRecognitionStart(questionId) {
    this.setState({rawQuestions: this.state.rawQuestions.map(question=> {
      if(question.id == questionId) {
        return {
          ...question,
          voiceRecognitionStarted: true
        }
      }
      return {...question}
    })}, ()=> {
      this.normalizeData()
    })

  }

  onVoiceRecognitionResultEnd(questionId, finalScript) {
    console.log('alee omalaa')
    console.log("MILANOOOO ", questionId, finalScript)
    this.setState({
      voiceRecognitionStarted: false,
      rawQuestions: this.state.rawQuestions.map((question,i)=> {
        if(question.id == questionId) {
          const answer = question.answer? `${question.answer} ${finalScript}`: finalScript
          return {
            ...question,
            answer
          }
        }
        return {
          ...question
        }
      })
    }, ()=> {
      this.normalizeData()
    })
  }

  handleBasicAnswerInput(questionId, answer) {
    this.setState({rawQuestions: this.state.rawQuestions.map(question => {
      if (question.id == questionId) {
        return {
          ...question,
          answer
        }
      }
      return {...question}
    })}, ()=> {
      this.normalizeData()
    })
  }

  handleCheckboxBtnChange(questionIndex, answerIndex, questionId) {
    let newQuestions = [...this.state.rawQuestions]
    let isChecked = _.find(this.state.rawQuestions, (q)=> q.id == questionId ).options[answerIndex].checked
    _.find(this.state.rawQuestions, (q)=> q.id == questionId ).options[answerIndex].checked = !isChecked

    this.setState({
      rawQuestions: newQuestions
    },
    ()=> {
      this.normalizeData()
      const options = this.state.rawQuestions
      .filter(q=> q.id == questionId)[0].options
      axios.put(`/questions/${questionId}`, {
        additional_data: {
          options
        }
      })
      .then((response)=> {})
      .catch((error)=> console.log(error))
    })
  }

  handleRadioBtnChange(questionIndex, answerIndex, questionId) {
    this.setState({rawQuestions: this.state.rawQuestions.map((question, index)=> {
      if (question.id == questionId) {
        return {
          ...question,
          options: question.options.map((answer, i)=> {
            if(answerIndex == i) {
              return {
                ...answer,
                checked: true
              }
            }
            return {
              ...answer,
              checked: false
            }
          })
        }
      }
      return {...question}
    })},
    ()=> {
      this.normalizeData()
      const options = this.state.rawQuestions
      .filter(q=> q.id == questionId)[0].options
      axios.put(`/questions/${questionId}`, {
        additional_data: {
          options
        }
      })
      .then((response)=> {})
      .catch((error)=> console.log(error))
    })
  }


  handlePreviewSelect(questionId, answerId) {
    let newQuestions = [...this.state.questions]

    this.setState({questions: this.state.questions.map((question, index)=> {
      if (questionId == question.id) {
        return {
          ...question,
          options: question.options.map((answer, i)=> {
            if(answerId == answer.id) {
              return {
                ...answer,
                checked: true
              }
            }
            return {
              ...answer,
              checked: false
            }
          })
        }
      }
      return {...question}
    })},
    ()=> {
      const options = this.state.questions
      .filter(q=> q.id == questionId)[0].options
      axios.put(`/questions/${questionId}`, {
        additional_data: {
          options
        }
      })
      .then((response)=> {})
      .catch((error)=> console.log(error))
    })

  }

  removeQuestion(questionId) {
    axios.delete(`/questions/${questionId}`)
    .then((response)=> {
      this.setState({
        rawQuestions: this.state.rawQuestions.filter(q=> q.id != questionId )
      })
      this.normalizeData()
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  handleLogicClick(questionId) {
    this.setState({
      modalQuestions: this.state.modalQuestions.map(question=> {
        if(question.id == questionId) {
          return {
            ...question,
            checked: true
          }
        }
        return {
          ...question,
          checked: false
        }
      })
    })
  }


  renderAnswerTemplateBody(questionId) {

    return answerTemplates.map((defaultAnswersItem, i) => {
      return (
        <div 
          key={i} 
          className="default-answer-item-wrapper"
          onClick={()=> this.handleAnswerTemplateItemClick(defaultAnswersItem)}
        >
          <div key={i} className="default-answers-item">
          {
            defaultAnswersItem.map((answer,index)=> {
              return <div key={index} className={`label ${answer.label}`}> {answer.value} </div>
            })
          }
        </div>
        <span className="default-answer-checked glyphicon glyphicon-ok"></span>
      </div>
      )

    })
  }

  handleAnswerTemplateItemClick(answerTemplate) {
    const {currentQuestionId} = this.state
    
    this.setState({
      modalShow: false,
      rawQuestions: this.state.rawQuestions.map((question, i)=> {
        if(question.id == this.state.currentQuestionId) {
          return {
            ...question,
            options: answerTemplate
          }
        }
        return {...question}
      })
    },
    ()=> {
      this.normalizeData()
      const options = this.state.rawQuestions.filter(q=> q.id == currentQuestionId)[0].options
      axios.put(`/questions/${currentQuestionId}`, {
        additional_data: {
          options
        }
      })
      .then((response)=>{})
      .catch((error)=> console.log(error))
    })
  }


  showAnswerTemplateModal(questionId) {
    this.setState({
      modalShow: true,
      currentQuestionId: questionId
    })
  }

  handleAddLogicBtn(questionId, option) {
    axios.post(`/questions`, {
      'digital_form_id': this.props.digitalFormId,
      'parent_question_id': questionId
    })
    .then((response)=> {
      this.setState({
        rawQuestions: this.state.rawQuestions.map(question=> {
          if (question.id == questionId) {
            return {
              ...question,
              options: question.options && question.options.map(o=> {

                if (o.id == option.id) {
                  return {
                    ...o,
                    logic_items: o.logic_items.concat({question_id: response.data.id})
                  }
                }
                return {...o}
              })
            }
          }
      
          return {...question}
        }).concat(response.data),
      },
      ()=> {
        this.normalizeData()
        const options = this.state.rawQuestions.filter(q=> q.id == questionId)[0].options
      axios.put(`/questions/${questionId}`, {
        additional_data: {
          options
        }
      })
      .then((response)=> console.log(response.data))
      .catch((error)=> console.log(error))
      })
    })
    .catch(function (error) {
      console.log(error);
    });


  }


  handleLinearScaleAddLogicChange(questionId, value) {
    this.setState({
      rawQuestions: this.state.rawQuestions.map(question => {
        if (question.id == questionId) {
          return {
            ...question,
            linearScaleData: { 
              ...question.linearScaleData,
              currentLogicItemValue: value,
              
            }
          }
        }
        return {...question}
      })
    }, ()=> {
      this.normalizeData()
    })
  }


  handleLinearScalePreviewChange(questionId, value) {
    this.setState({
      rawQuestions: this.state.rawQuestions.map(question=> {
        if(question.id == questionId) {
          return {
            ...question,
            linearScaleData: {
              ...question.linearScaleData,
              options: question.linearScaleData.options.map(option => {
                if(option.value == value) {
                  return {
                    ...option,
                    checked: true
                  }
                }
                return {
                  ...option,
                  checked: false
                }
              })
            }
          }
        }
        return {...question}
      })
    }, ()=> {
    this.normalizeData()

    })
  }

  addLogicOnLinearScaleClick(questionId) {

    axios.post(`/questions`, {
      'digital_form_id': this.props.digitalFormId,
      'parent_question_id': questionId
    })
    .then((response)=> {
      this.setState({
        rawQuestions: this.state.rawQuestions.map(question=> {
          if (question.id == questionId) {
            return {
              ...question,
              linearScaleData: {
                ...question.linearScaleData,
                options: question.linearScaleData.options && question.linearScaleData.options.map(o=> {
                    if (o.value == question.linearScaleData.currentLogicItemValue) {
                      return {
                        ...o,
                        logic_items: o.logic_items.concat({question_id: response.data.id})
                      }
                    }
                    return {...o}
                  })
              }
            }
          }
      
          return {...question}
        }).concat(response.data),
      },
      ()=> {
        this.normalizeData()
        const linear_scale_data = this.state.rawQuestions.filter(q=> q.id == questionId)[0].linearScaleData
      axios.put(`/questions/${questionId}`, {
        additional_data: {
          linear_scale_data
        }
      })
      .then((response)=> console.log(response))
      .catch((error)=> console.log(error))
      })
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  }

  render () {
    const { questions, title, voiceRecognitionStarted, voiceRecognitionStopped, isFormSubmitted } = this.state
    

    console.log('QUESTION STATE ', this.state.questions)
    return (
      <div className="container" style={{padding: '30px 15px'}}>

        <Modal show={this.state.modalShow} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Answer Templates</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.renderAnswerTemplateBody()}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="row">
          <div className="col-xs-12">
            <div className="form-group">
              <label htmlFor="formName">Form Name</label>
              <input 
                type="text"
                className="form-control" 
                placeholder="Enter Digital Form Title" 
                value={title}
                onChange={(e)=> this.handleDigitalFormTitleChange(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="row">
          
          <div className="col-xs-12 col-sm-7">
            <FormBuilderEditor 
              addQuestion={this.addQuestion.bind(this)}
              questions={questions}
              handleAnswerTypeDropdown={this.handleAnswerTypeDropdown.bind(this)}
              addMultipleAnswer={this.addMultipleAnswer.bind(this)}
              removeMultipleAnswer={this.removeMultipleAnswer.bind(this)}
              changeMultipleAnswerValue={this.changeMultipleAnswerValue.bind(this)}
              handleQuestionTitleChange={this.handleQuestionTitleChange.bind(this)}
              toggleisRequired={this.toggleisRequired.bind(this)}
              handleLinearScaleChange={this.handleLinearScaleChange.bind(this)}
              handleLinearScaleLabelChange={this.handleLinearScaleLabelChange.bind(this)}
              onDragEnd={this.onDragEnd.bind(this)}
              removeQuestion={this.removeQuestion.bind(this)}
              handleAddLogicBtn={this.handleAddLogicBtn.bind(this)}
              showAnswerTemplateModal={this.showAnswerTemplateModal.bind(this)}
              handleLinearScaleAddLogicChange={this.handleLinearScaleAddLogicChange.bind(this)}
              addLogicOnLinearScaleClick={this.addLogicOnLinearScaleClick.bind(this)}
            />
          </div>
          
          <div className="col-xs-12 col-sm-5">
            <FormBuilderPreview 
              questions={questions}
              onVoiceRecognitionEnd={this.onVoiceRecognitionEnd.bind(this)}
              voiceRecognitionStart={this.voiceRecognitionStart.bind(this)}
              voiceRecognitionStop={this.voiceRecognitionStop.bind(this)}
              onVoiceRecognitionResultEnd={this.onVoiceRecognitionResultEnd.bind(this)}
              voiceRecognitionStarted={voiceRecognitionStarted}
              voiceRecognitionStopped={voiceRecognitionStopped}
              isFormSubmitted={isFormSubmitted}
              handleBasicAnswerInput={this.handleBasicAnswerInput.bind(this)}
              handleRadioBtnChange={this.handleRadioBtnChange.bind(this)}
              handleCheckboxBtnChange={this.handleCheckboxBtnChange.bind(this)}
              handlePreviewSelect={this.handlePreviewSelect.bind(this)}
              handleLinearScalePreviewChange={this.handleLinearScalePreviewChange.bind(this)}
            />
          </div>
        </div>
        <div className="col-xs-12 col-sm-8 col-sm-offset-2">
          <button 
            onClick={this.addQuestion.bind(this)} 
            className="btn btn-block btn-success"> 
            Add Question
          </button>
        </div>


      </div>
    );
  }
}

export default FormBuilder
