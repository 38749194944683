import React from "react";
import PropTypes from "prop-types";
import FabricCanvas from './fabric_canvas/FabricCanvas';

import _ from "lodash";

import './card_builder.scss'

class CardBuilder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      customFields: [],
      customFieldName: '',
      removingCustomField: false,
      clonedObjects: [],
    }

    this.cardFrontRef = React.createRef();
    this.cardBackRef = React.createRef();
    this.cardWallRef = React.createRef();


    this.updateCustomFields = this.updateCustomFields.bind(this);
    this.removeCustomFieldItem = this.removeCustomFieldItem.bind(this);
    this.removeActiveCustomFieldItem = this.removeActiveCustomFieldItem.bind(this);
  }

  componentDidMount() {
    const { cardFrontJson, cardBackJson, cardWallJson } = this.props;
    let frontData = this.generateData(cardFrontJson);
    let backData = this.generateData(cardBackJson);
    let wallData = this.generateData(cardWallJson);

    const data = _.union(frontData, backData, wallData);

    const fields = [];

    data.forEach(obj => {
      if (obj.customField) {
        fields.push(obj);
      }
    })

    const customFields = _.uniqBy(fields, function (e) {
      return e.custom_field_id;
    });


    this.setState({
      customFields,
      clonedObjects: customFields
    });

  }



  addToClonedObjects(object, cb) {
    console.log("CLONE OBJEct ", object)
    this.setState({
      clonedObjects: this.state.clonedObjects.concat(object)
    }, () => {
      cb();
    })
  }

  isEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }

    return JSON.stringify(obj) === JSON.stringify({});
  }

  generateData(json) {
    // console.log("JSSON ", json)

    if (json == '') return [];

    if (!this.isEmpty(json)) {
      return JSON.parse(json).objects;
    }

    return []
  }

  handleCustomFieldNameChange(value) {
    if (value) {
      this.setState({
        customFieldName: value
      })
    }
  }

  setCustomFieldItems(customFields, cb) {
    console.log("cussss", customFields)
    this.setState({
      customFields
    }, () => {
      this.setState({
        customFields: this.state.customFields.map((field, i) => {
          return {
            ...field,
            order: i
          }
        })
      }, () => {
        console.log("ccccccc ", this.state.customFields)
        cb();
      })
    })
  }

  toggleRemovingCustomField() {
    this.setState({
      removingCustomField: !this.state.removingCustomField
    })
  }


  updateCustomFields(name, id, type) {
    type = type || 'text';
    const contains = this.state.customFields.some(field => field.id == id);
    const iconType = type == "separator" ? "glyphicon-minus" : "glyphicon-font";

    if (!contains) {
      return this.setState({
        customFields: this.state.customFields.concat({
          name,
          icon: `glyphicon ${iconType}`,
          id,
          type,
          dropdown: (type == 'dropdown') ? true : false,
          options: [],
        })
      })
    }
  }

  updateClonedObject(id, val) {
    return this.setState({
      customFields: this.state.clonedObjects.map(obj => {
        if (obj.id == id) {
          return {
            ...obj,
            text: val,
            previewValue: val
          }
        }

        return obj;
      })
    })
  }

  removeCustomFieldItem(fieldId, cb) {
    this.setState({
      customFields: this.state.customFields.filter(field => field.id != fieldId),
      removingCustomField: true
    }, cb)
  };

  removeActiveCustomFieldItem(id, cb) {
    this.setState({
      customFields: this.state.customFields.filter(field => field.id != id),
    }, cb)
  }

  updateClonedCustomFieldTextValue(name, value) {
    this.cardFrontRef.current.updateClonedCustomFieldTextValue(name, value);
    this.cardBackRef.current.updateClonedCustomFieldTextValue(name, value);
    this.cardWallRef.current.updateClonedCustomFieldTextValue(name, value);
  }

  updateCustomClonedFieldDropdown(name, options) {
    this.cardFrontRef.current.updateCustomClonedFieldDropdown(name, options);
    this.cardBackRef.current.updateCustomClonedFieldDropdown(name, options);
    this.cardWallRef.current.updateCustomClonedFieldDropdown(name, options);
  }

  updateClonedCustomFieldCheckBox(name, canvasValue, value) {
    this.cardFrontRef.current.updateClonedCustomFieldCheckBox(name, canvasValue, value);
    this.cardBackRef.current.updateClonedCustomFieldCheckBox(name, canvasValue, value);
    this.cardWallRef.current.updateClonedCustomFieldCheckBox(name, canvasValue, value);
  }

  updateClonedCustomFieldImage(name, src, width, height) {
    this.cardFrontRef.current.updateClonedCustomFieldImage(name, src, width, height);
    this.cardBackRef.current.updateClonedCustomFieldImage(name, src, width, height);
    this.cardWallRef.current.updateClonedCustomFieldImage(name, src, width, height);
  }

  onTabclick() {
    this.cardFrontRef.current.discardActiveObject();
    this.cardBackRef.current.discardActiveObject();
    this.cardWallRef.current.discardActiveObject();
  }
  render() {

    const {
      customFields,
      customFieldName,
      removingCustomField,
      clonedObjects
    } = this.state;

    const {
      img_src,
      cardFront,
      cardBack,
      wall,
      mainFormId,
      cardFrontJson,
      cardBackJson,
      cardWallJson,
      populateRealValues,
      course_id,
      card_id,
      redirect_back_url,
      card_front_import,
      card_back_import,
      card_wall_import,
      trainee_signature_img,
      employer_signature_img
    } = this.props;

    console.log("redd", trainee_signature_img,
      employer_signature_img)

    return (
      <div>

        <header className="header">

          <div className="logo-wrapper">
            <h5>Powered By</h5>
            <img src={this.props.logo_src} />
          </div>

          <ul className="nav nav-tabs" role="tablist">
            <li role="presentation" className="active" onClick={this.onTabclick.bind(this)}>
              <a
                href="#card-front"
                aria-controls="card-front"
                role="tab"
                data-toggle="tab"

              >Card Front
              </a>
            </li>
            <li role="presentation" onClick={this.onTabclick.bind(this)}>
              <a
                href="#card-back"
                aria-controls="card-back"
                role="tab"
                data-toggle="tab"

              >Card Back
              </a>
            </li>
            <li role="presentation" onClick={this.onTabclick.bind(this)}>
              <a
                href="#card-wall"
                aria-controls="card-wall"
                role="tab"
                data-toggle="tab"

              >
                Wall Certificate
              </a>
            </li>
          </ul>

          <div className="right-panel">
            <a style={{
              padding: "5px 10px"
            }}
              href={this.props.redirect_back_url}
              className="btn btn-danger btn-sm pull-right"
            >
              Exit Without Changes
            </a>
          </div>

        </header>

        <div className="tab-content">
          <div
            role="tabpanel"
            className="tab-pane active"
            id="card-front"
          >
            <FabricCanvas
              ref={this.cardFrontRef}
              type="cardFront"
              width={783}
              height={513}
              bleedValue={27}
              img_src={img_src}
              canvasName={cardFront}
              mainFormId={mainFormId}
              canvasJson={cardFrontJson}
              handleCustomFieldNameChange={this.handleCustomFieldNameChange.bind(this)}
              updateCustomFields={this.updateCustomFields}
              customFields={customFields}
              customFieldName={customFieldName}
              removeCustomFieldItem={this.removeCustomFieldItem}
              removeActiveCustomFieldItem={this.removeActiveCustomFieldItem}
              setCustomFieldItems={this.setCustomFieldItems.bind(this)}
              populateRealValues={populateRealValues}
              course_id={course_id}
              card_id={card_id}
              removingCustomField={removingCustomField}
              toggleRemovingCustomField={this.toggleRemovingCustomField.bind(this)}
              addToClonedObjects={this.addToClonedObjects.bind(this)}
              clonedObjects={clonedObjects}
              updateClonedCustomFieldTextValue={this.updateClonedCustomFieldTextValue.bind(this)}
              updateCustomClonedFieldDropdown={this.updateCustomClonedFieldDropdown.bind(this)}
              updateClonedCustomFieldCheckBox={this.updateClonedCustomFieldCheckBox.bind(this)}
              updateClonedCustomFieldImage={this.updateClonedCustomFieldImage.bind(this)}
              updateClonedObject={this.updateClonedObject.bind(this)}
              card_import_data={card_front_import}
              trainee_signature_img={trainee_signature_img}
              employer_signature_img={employer_signature_img}
            />
          </div>
          <div role="tabpanel" className="tab-pane" id="card-back">
            <FabricCanvas
              ref={this.cardBackRef}
              type="cardBack"
              width={783}
              height={513}
              bleedValue={27}
              img_src={img_src}
              canvasName={cardBack}
              mainFormId={mainFormId}
              canvasJson={cardBackJson}
              handleCustomFieldNameChange={this.handleCustomFieldNameChange.bind(this)}
              updateCustomFields={this.updateCustomFields}
              customFields={customFields}
              removeCustomFieldItem={this.removeCustomFieldItem}
              customFieldName={customFieldName}
              removeActiveCustomFieldItem={this.removeActiveCustomFieldItem}
              setCustomFieldItems={this.setCustomFieldItems.bind(this)}
              populateRealValues={populateRealValues}
              course_id={course_id}
              card_id={card_id}
              removingCustomField={removingCustomField}
              toggleRemovingCustomField={this.toggleRemovingCustomField.bind(this)}
              addToClonedObjects={this.addToClonedObjects.bind(this)}
              clonedObjects={clonedObjects}
              updateClonedCustomFieldTextValue={this.updateClonedCustomFieldTextValue.bind(this)}
              updateCustomClonedFieldDropdown={this.updateCustomClonedFieldDropdown.bind(this)}
              updateClonedCustomFieldCheckBox={this.updateClonedCustomFieldCheckBox.bind(this)}
              updateClonedCustomFieldImage={this.updateClonedCustomFieldImage.bind(this)}
              updateClonedObject={this.updateClonedObject.bind(this)}
              card_import_data={card_back_import}
              trainee_signature_img={trainee_signature_img}
              employer_signature_img={employer_signature_img}
            />
          </div>
          <div role="tabpanel" className="tab-pane" id="card-wall">
            <FabricCanvas
              ref={this.cardWallRef}
              type="wall"
              width={810}
              height={630}
              bleedValue={9}
              img_src={img_src}
              canvasName={wall}
              mainFormId={mainFormId}
              canvasJson={cardWallJson}
              handleCustomFieldNameChange={this.handleCustomFieldNameChange.bind(this)}
              updateCustomFields={this.updateCustomFields}
              removeCustomFieldItem={this.removeCustomFieldItem}
              customFields={customFields}
              customFieldName={customFieldName}
              removeActiveCustomFieldItem={this.removeActiveCustomFieldItem}
              setCustomFieldItems={this.setCustomFieldItems.bind(this)}
              populateRealValues={populateRealValues}
              course_id={course_id}
              card_id={card_id}
              removingCustomField={removingCustomField}
              toggleRemovingCustomField={this.toggleRemovingCustomField.bind(this)}
              addToClonedObjects={this.addToClonedObjects.bind(this)}
              clonedObjects={clonedObjects}
              updateClonedCustomFieldTextValue={this.updateClonedCustomFieldTextValue.bind(this)}
              updateCustomClonedFieldDropdown={this.updateCustomClonedFieldDropdown.bind(this)}
              updateClonedCustomFieldCheckBox={this.updateClonedCustomFieldCheckBox.bind(this)}
              updateClonedCustomFieldImage={this.updateClonedCustomFieldImage.bind(this)}
              updateClonedObject={this.updateClonedObject.bind(this)}
              card_import_data={card_wall_import}
              trainee_signature_img={trainee_signature_img}
              employer_signature_img={employer_signature_img}
            />
          </div>
        </div>

      </div>
    );
  }
}

export default CardBuilder;
