import _ from 'lodash'

export function normalizeData(data) {
  const questions = data.questions.filter(q=> !q.parent_question_id)

  const newQuestions = questions.map(q=> {
    const logicItems = []
    
    q.additional_data.options && q.additional_data.options.forEach(o => {
      o.logic_items && o.logic_items.forEach(l=> {
          logicItems.push(l.question_id)
      })
    })

      return {
      ...q,
      subQuestions: logicItems.map(item => {
        return _.find(data.questions, (q)=> q.id == item )
      })
    }
    
  })
  
  return newQuestions
}
