import React from "react"
import PropTypes from "prop-types"
import './default_preview_answer.scss'
import {capitalize, iconName} from '../../../helper'
import VoiceToText from '../../voice_to_text/VoiceToText'


class DefaultPreviewAnswer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      answers: [
        {
          title: 'Compliant',
          kind: 0,
          checked: false,
        },
        {
          title: 'Non-Compliant',
          kind: 1,
          checked: false,
        },
        {
          title: 'N/A',
          kind: 2,
          checked: false,
        },
      ]
    }
  }

  labelClass(checked) {
    return checked ? 'default-answer-checked': ''
  }

  handleClick(index) {
    this.setState({
      answers: this.state.answers.map((answer, i)=> {
        if (index == i) {
          return {
            ...answer,
            checked: true
          }
        }
        return {
          ...answer,
          checked: false
        }
      })
    })
  }

  render () {
    const { title, required,  answer} = this.props
    const hasError = required && !answer ? 'has-error': ''

    

    return (
      <div className={`default-preview-answer ${hasError}`}>
        <div className="paragraph-preview-answer-title-section">
          <label className="control-label"> 
            {title} 
            {required && <strong className="red-color">*</strong>}
          </label>
          <div className="default-preview-answers">
          {
            this.state.answers.map((answer,index)=> {
              
              return (
                <div 
                  key={index} 
                  onClick={()=> this.handleClick(index)}
                  className={`default-preview-answer ${this.labelClass(answer.checked)}`}
                >
                  {answer.title}
                </div>
              )
            })
          }
          </div>
       
        {/* {hasError && <span className="error-message validation-error"> This Field Can't Be Blank </span> } */}
      
        </div>
      </div>
    );
  }
}

export default DefaultPreviewAnswer
