import React from "react"
import PropTypes from "prop-types"
import TextControls from "../TextControls/TextControls";

import './control_panel.scss'
import PreviewControls from "../PreviewControls/PreviewControls";
import ShapeControls from "../ShapeControls/ShapeControls";

class ControlPanel extends React.Component {

  renderPanel() {
    const {
      activeObject,
      type,
      changeActiveObjectFill,
      changeActiveObjectFontSize,
      handleActiveObjectOpacity,
      handleActiveObjectLeft,
      handleActiveObjectTop,
      alignTextLeft,
      alignTextCenter,
      makeTextStyle,
      alignTextRight,
      handleTextFontFamily,
      handleTextStyle,
      handleDateFormatChange,
      handleActiveObjectStrokeWidth,
      handleDropDownOptionInput,
      handleActiveObjectWidth,
      handleActiveObjectHeight,
      addDropDownOptionField,
      removeDropDownOptionField,
      setActiveObjectOptionsOrder,
      populateRealValues,
      handleCustomFieldCheckbox,
      updateImage,
      handleCustomTextFieldValue,
      handleDropDownSelect,
      moveLayerForward,
      moveLayerBackward
    } = this.props

    switch (type) {
      case 'textbox':
        return <TextControls
          activeObject={activeObject}
          changeActiveObjectFill={changeActiveObjectFill}
          changeActiveObjectFontSize={changeActiveObjectFontSize}
          handleActiveObjectOpacity={handleActiveObjectOpacity}
          handleActiveObjectLeft={handleActiveObjectLeft}
          handleActiveObjectTop={handleActiveObjectTop}
          handleActiveObjectWidth={handleActiveObjectWidth}
          handleActiveObjectHeight={handleActiveObjectHeight}
          alignTextLeft={alignTextLeft}
          alignTextCenter={alignTextCenter}
          alignTextRight={alignTextRight}
          handleTextFontFamily={handleTextFontFamily}
          handleTextStyle={handleTextStyle}
          handleDateFormatChange={handleDateFormatChange}
          makeTextStyle={makeTextStyle}
          addDropDownOptionField={addDropDownOptionField}
          removeDropDownOptionField={removeDropDownOptionField}
          handleDropDownOptionInput={handleDropDownOptionInput}
          setActiveObjectOptionsOrder={setActiveObjectOptionsOrder}
          populateRealValues={populateRealValues}
          handleCustomFieldCheckbox={handleCustomFieldCheckbox}
          handleCustomTextFieldValue={handleCustomTextFieldValue}
          handleDropDownSelect={handleDropDownSelect}
          moveLayerForward={moveLayerForward}
          moveLayerBackward={moveLayerBackward}
        />
        break
      default:
        return <ShapeControls
          activeObject={activeObject}
          changeActiveObjectFill={changeActiveObjectFill}
          changeActiveObjectFontSize={changeActiveObjectFontSize}
          handleActiveObjectOpacity={handleActiveObjectOpacity}
          handleActiveObjectLeft={handleActiveObjectLeft}
          handleActiveObjectTop={handleActiveObjectTop}
          handleActiveObjectWidth={handleActiveObjectWidth}
          handleActiveObjectHeight={handleActiveObjectHeight}
          handleActiveObjectStrokeWidth={handleActiveObjectStrokeWidth}
          updateImage={updateImage}
          populateRealValues={populateRealValues}
          moveLayerForward={moveLayerForward}
          moveLayerBackward={moveLayerBackward}
        />
    }
  }

  renderControlPanel() {
    const {
      activeObject,
      isInPreviewMode,
      defaultFields,
      changePreviewText,
      changePreviewDate,
      addCustomFieldImg,
      customFields,
      changePreviewForCheckbox,
      changePreviewDropDown,
      clonedObjects,
      changePreviewImage
    } = this.props

    if (isInPreviewMode) {
      return <PreviewControls
        defaultFields={defaultFields}
        changePreviewText={changePreviewText}
        changePreviewDate={changePreviewDate}
        addImage={addCustomFieldImg}
        customFields={customFields}
        changePreviewForCheckbox={changePreviewForCheckbox}
        changePreviewDropDown={changePreviewDropDown}
        clonedObjects={clonedObjects}
        changePreviewImage={changePreviewImage}
      />
    } else if (activeObject) {
      return this.renderPanel()
    }

    return <div style={{ color: '#ccc', fontSize: 16 }}> No Selection </div>
  }

  render() {
    const {
      activeObject,
      saveCanvas,
      previewCanvas,
      editCanvas,
      isInPreviewMode,
      resetCanvas,
      card_import_data
    } = this.props;


    const noSelection = () => {
      // (!activeObject || !isInPreviewMode) ? 'no-selection' : ''
      if ((!activeObject && isInPreviewMode) || activeObject) {
        return ''
      } else if (!activeObject && !isInPreviewMode) {
        return 'no-selection'
      }
    }

    return (
      <div className="control-panel-wrapper">
        <div className="col-xs-12 control-btns-wrapper">
          <button
            onClick={(e) => {
              e.preventDefault()
              resetCanvas(e)
            }}
            type="button"
            className="btn btn-danger btn-block">Reset canvas</button>

          {
            isInPreviewMode ? (
              <button
                onClick={(e) => editCanvas(e)}
                className="btn btn-default btn-block">Edit</button>
            ) : (
                <React.Fragment>
                  <button
                    onClick={(e) => previewCanvas(e)}
                    className="btn btn-default btn-block">Preview</button>

                </React.Fragment>
              )
          }


          <button
            type="button"
            className="btn btn-primary btn-block dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="true"
          >
            Import
          </button>

          <ul
            style={{
              width: '100%',
              height: '400px',
              padding: '0',
              overflowY: 'auto'
            }}
            className="js-fields-dropdown dropdown-menu animated bounceIn myBounce list-group" aria-labelledby="dropdownMenu1">
            <div href="#" className="list-group-item disabled" style={{ cursor: 'auto' }}>
              <strong>List Of Templates</strong>
            </div>
            {
              card_import_data.map((data, i) => {
                return (
                  <a href={data.url} key={i } className="list-group-item">{data.title}</a>
                )
              })
            }

          </ul>







          <button className="btn btn-success btn-block" onClick={(e) => saveCanvas(e)}>Save Design</button>
        </div>

        <div className={`control-panel-sections col-xs-12 no-padding ${noSelection()}`}>
          {this.renderControlPanel()}
        </div>

      </div>
    );
  }
}

export default ControlPanel
