import React from "react"
import PropTypes from "prop-types"
import './FormBuilderEditor.scss'
import Question from './question/Question'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: '10px 20px 20px',
  margin: `0 0 ${10}px 0`,
  border: '1px solid #e3e3e3',
  zIndex: -1,
  // styles we need to apply on draggables
  ...draggableStyle
});


const getListStyle = isDraggingOver => ({
  padding: 0,
  width: '100%'
});

class FormBuilderEditor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalShow: false
    }

  }


  getText(q) {
    const parentQuestion = this.props.questions.filter(question => question.id == q.parent_question_id)[0]
    
    let parentTitle = ''
    const logicItems = []

    if(parentQuestion.kind == 'linear_scale') {
      parentQuestion.linearScaleData.options.forEach(option => {
        option.logic_items.map(l => {
          if (l.question_id == q.id) {
            parentTitle = option.value
          }
        })
      })
    } else {
      parentQuestion.options.forEach(option => {
        option.logic_items.map(l => {
          if (l.question_id == q.id) {
            parentTitle = option.value
          }
        })
      })
    }

    return parentTitle

  }

  
  render () {
    const {questions, addQuestion, 
      handleAnswerTypeDropdown, addMultipleAnswer, handleQuestionTitleChange, removeMultipleAnswer, changeMultipleAnswerValue, toggleisRequired, handleLinearScaleLabelChange, handleLinearScaleChange, onDragEnd, addLogic, removeQuestion, handleAddLogicBtn, showAnswerTemplateModal, handleLinearScaleAddLogicChange, addLogicOnLinearScaleClick} = this.props
    
    return (
      <div className="form-builder-editor">
        <div className="panel panel-default">

          <div className="panel-heading ">
            <h3>FORM BUILDER</h3>
            {/* <button className="btn btn-default" onClick={()=> addQuestion()}>Add Question</button> */}
          </div>
          <div className="panel-body">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {questions.map((question, index) => (
                    <Draggable key={question.id} draggableId={question.id} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                          className={`single-draggable-item ${question.parent_question_id && 'nested'}`}

                        >
                          <Question 
                            key={index} 
                            index={index}
                            question={question} 
                            handleAnswerTypeDropdown={handleAnswerTypeDropdown}
                            handleQuestionTitleChange={handleQuestionTitleChange}
                            addMultipleAnswer={addMultipleAnswer}
                            removeMultipleAnswer={removeMultipleAnswer}
                            changeMultipleAnswerValue={changeMultipleAnswerValue}
                            toggleisRequired={toggleisRequired}
                            handleLinearScaleChange={handleLinearScaleChange}
                            handleLinearScaleLabelChange={handleLinearScaleLabelChange}
                            addLogic={addLogic}
                            removeQuestion={removeQuestion}
                            handleAddLogicBtn={handleAddLogicBtn}
                            showAnswerTemplateModal={showAnswerTemplateModal}
                            handleLinearScaleAddLogicChange={handleLinearScaleAddLogicChange}
                            addLogicOnLinearScaleClick={addLogicOnLinearScaleClick}
                            />
                            {
                              question.subQuestions && _.compact(question.subQuestions).map((q,index)=> {
                                return (
                                  <Question 
                                    key={index} 
                                    index={index}
                                    subQuestion={true}
                                    textValue={this.getText(q)}
                                    question={q} 
                                    parentQuestiondId={question.id}
                                    handleAnswerTypeDropdown={handleAnswerTypeDropdown}
                                    handleQuestionTitleChange={handleQuestionTitleChange}
                                    addMultipleAnswer={addMultipleAnswer}
                                    removeMultipleAnswer={removeMultipleAnswer}
                                    changeMultipleAnswerValue={changeMultipleAnswerValue}
                                    toggleisRequired={toggleisRequired}
                                    handleLinearScaleChange={handleLinearScaleChange}
                                    handleLinearScaleLabelChange={handleLinearScaleLabelChange}
                                    removeQuestion={removeQuestion}
                                    handleAddLogicBtn={handleAddLogicBtn}
                                    showAnswerTemplateModal={showAnswerTemplateModal}
                                    />
                                )
                              })
                            }
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
      </div>
    );
  }
}

export default FormBuilderEditor


