import React from "react"
import PropTypes from "prop-types"
import './multiple_answer_item.scss'

class Radio extends React.Component {
  handleLogic(questionId, optionValue, option) {
   
    this.props.handleAddLogicBtn(questionId, optionValue, option)
  }
  
  render () {

    const {number, optionValue, option , removeMultipleAnswer, questionId, 
      changeMultipleAnswerValue, id, handleAddLogicBtn, subQuestion} = this.props

    return (
      <div className="radio-wrapper row multiple-answer-item-wrapper">
        <div className="col-xs-12 col-sm-8 radio-input-wrapper">
          <span> {number+1}. </span>
          <input 
            type="text" 
            className="form-control radio-input-field" 
            placeholder="Enter Answer" 
            value={optionValue || ''}
            onChange={(e)=> changeMultipleAnswerValue(e.target.value, questionId, id)}
          />
        </div>
        {
          !subQuestion && (
            <div className="col-xs-12 col-sm-4 radio-btn-group">
              <span 
                onClick={()=> this.handleLogic(questionId, option)} 
                className="label label-info">
                ADD LOGIC
              </span>
              <span style={{zIndex: 1212}}onClick={()=> removeMultipleAnswer(questionId, id)} className="label label-danger">REMOVE</span>
            </div>

          )
        }
      </div>
    );
  }
}

export default Radio
