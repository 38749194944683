import React from "react"
import PropTypes from "prop-types"
import './paragraph_answer.scss'
import {capitalize, iconName} from '../../../helper'

class ParagraphAnswer extends React.Component {

  render () {
    return (
      <div className="paragraph-answer">
        <span className="paragraph-answer-title">Answer Type:</span>
        <div className="">
          <span className="glyphicon glyphicon-text-height" aria-hidden="true"></span>
          <span className="answer-type-text">Paragraph</span>
        </div>
      </div>
    );
  }
}

export default ParagraphAnswer
