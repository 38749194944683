import React from "react"
import PropTypes from "prop-types"

import onClickOutside from "react-onclickoutside";
import ReactSlider from 'react-slider'
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { SketchPicker } from 'react-color'

import './text_controls.scss'


const fonts = [
  {
    name: 'Abril Fatface',
    italic: false,
    weights: ['normal']
  },
  {
    name: 'Alegreya',
    italic: true,
    weights: ['normal', 'bold']
  },
  {
    name: 'Arimo',
    italic: true,
    weights: ['normal', 'bold']
  },
  {
    name: 'Arvo',
    italic: true,
    weights: ['normal', 'bold']
  },
  {
    name: 'Cardo',
    italic: false,
    weights: ['normal', 'bold']
  },
  {
    name: 'Cinzel',
    italic: false,
    weights: ['normal', 'bold']
  },
  {
    name: 'Exo 2',
    italic: true,
    weights: ['normal', 'bold']
  },
  {
    name: 'Glegoo',
    italic: false,
    weights: ['normal', 'bold']
  },
  {
    name: 'Gravitas One',
    italic: false,
    weights: ['normal']
  },
  {
    name: 'Great Vibes',
    italic: false,
    weights: ['normal']
  },
  {
    name: 'Josefin Sans',
    italic: true,
    weights: ['normal', 'bold']
  },
  {
    name: 'Josefin Slab',
    italic: true,
    weights: ['normal', 'bold']
  },
  {
    name: 'Lato',
    italic: true,
    weights: ['normal', 'bold']
  },
  {
    name: 'Libre Baskerville',
    italic: false,
    weights: ['normal', 'bold']
  },
  {
    name: 'Lora',
    italic: true,
    weights: ['normal', 'bold']
  },
  {
    name: 'Merriweather',
    italic: true,
    weights: ['normal', 'bold']
  },
  {
    name: 'Montserrat',
    italic: true,
    weights: ['normal', 'bold']
  },
  {
    name: 'Mulish',
    italic: true,
    weights: ['normal', 'bold']
  },
  {
    name: 'Noto Sans',
    italic: true,
    weights: ['normal', 'bold']
  },
  {
    name: 'Nunito',
    italic: true,
    weights: ['normal', 'bold']
  },
  {
    name: 'Old Standard TT',
    italic: false,
    weights: ['normal', 'bold']
  },
  {
    name: 'Open Sans',
    italic: true,
    weights: ['normal', 'bold']
  },
  {
    name: 'Open Sans Condensed',
    italic: false,
    weights: ['normal', 'bold']
  },
  {
    name: 'Oswald',
    italic: false,
    weights: ['normal', 'bold']
  },
  {
    name: 'Playfair Display',
    italic: true,
    weights: ['normal', 'bold']
  },
  {
    name: 'Poppins',
    italic: true,
    weights: ['normal', 'bold']
  },
  {
    name: 'PT Sans',
    italic: true,
    weights: ['normal', 'bold']
  },
  {
    name: 'PT Sans Narrow',
    italic: true,
    weights: ['normal', 'bold']
  },
  {
    name: 'PT Serif',
    italic: true,
    weights: ['normal', 'bold']
  },
  {
    name: 'Raleway',
    italic: true,
    weights: ['normal', 'bold']
  },
  {
    name: 'Roboto',
    italic: true,
    weights: ['normal', 'bold']
  },
  {
    name: 'Roboto Condensed',
    italic: true,
    weights: ['normal', 'bold']
  },
  {
    name: 'Roboto Mono',
    italic: true,
    weights: ['normal', 'bold']
  },
  {
    name: 'Roboto Slab',
    italic: false,
    weights: ['normal', 'bold']
  },
  {
    name: 'Slabo 27',
    italic: false,
    weights: ['normal']
  },
  {
    name: 'Source Sans Pro',
    italic: true,
    weights: ['normal', 'bold']
  },
  {
    name: 'Titillium Web',
    italic: true,
    weights: ['normal', 'bold']
  },
  {
    name: 'Ubuntu',
    italic: true,
    weights: ['normal', 'bold']
  },
  {
    name: 'Vollkorn',
    italic: true,
    weights: ['normal', 'bold']
  }
]

const SortableItem = SortableElement(({
  handleDropDownOptionInput,
  removeDropDownOptionField,
  item,
  i }) => {
  return (
    <div key={i} className="section-item sortable">
      <span className="sort-icon glyphicon glyphicon-align-justify"></span>
      <div className="section-entity title m-l-10">
        Option</div>
      <div className="section-entity full">
        <input
          key={i}
          className="control-panel-input full"
          type="text"
          value={item || ""}
          onChange={(e) => handleDropDownOptionInput(e.target.value, i)}
        />
      </div>
      <span onClick={(e) => {

        e.preventDefault();
        removeDropDownOptionField(i)
      }}
        className="label label-danger">
        <i className="glyphicon glyphicon-remove"></i>
      </span>
    </div>
  )
});


const SortableList = SortableContainer(({
  items,
  handleDropDownOptionInput,
  removeDropDownOptionField }) => {
  return (
    <ul style={{ paddingLeft: 0 }}>
      {items.map((value, index) => {
        return (
          <SortableItem
            key={`item-${index}`}
            i={index}
            index={index}
            item={value}
            handleDropDownOptionInput={handleDropDownOptionInput}
            removeDropDownOptionField={removeDropDownOptionField}
          />
        )
      }
      )}
    </ul>
  );
});



class TextControls extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isColorPickerOpened: false
    }
  }


  handleClickOutside = evt => {
    this.setState({ isColorPickerOpened: false })
  }


  handleColorChange({ hex }) {
    this.props.changeActiveObjectFill(hex)
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { activeObject, setActiveObjectOptionsOrder } = this.props;
    setActiveObjectOptionsOrder(arrayMove(activeObject.options, oldIndex, newIndex))
  };

  handleKeyDown(e) {
    if (e.which == 13) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  hasItalicFont() {
    const { activeObject } = this.props;

    return fonts.filter(f => f.name == activeObject.fontFamily)[0].italic;
  }

  render() {
    const { activeObject, changeActiveObjectFill, changeActiveObjectFontSize,
      handleActiveObjectOpacity, handleActiveObjectLeft, handleActiveObjectTop, makeTextStyle,
      handleActiveObjectWidth, handleActiveObjectHeight, addDropDownOptionField, alignTextLeft, alignTextCenter,
      alignTextRight, handleTextFontFamily, handleTextStyle, handleDateFormatChange,
      removeDropDownOptionField, handleDropDownOptionInput, populateRealValues, handleCustomFieldCheckbox,
      handleCustomTextFieldValue, handleDropDownSelect, moveLayerForward, moveLayerBackward } = this.props;


    console.log('WWWWW', fonts.filter(f => f.name == activeObject.fontFamily))

    const isCheckboxField = activeObject.fieldType == 'checkbox' ? true : false;
    const isTextField = activeObject.fieldType == 'text' ? true : false;

    return (
      <div style={{ margin: '10px 0', position: 'relative' }}>

        {
          activeObject.customField && (
            <div className="section section-arrange">
              <div className="section-header">
                <h5 className="section-header-title">Custom Field</h5>
              </div>
              <div className="section-body">
                <div className="section-item">
                  <span className="section-entity">Name: </span>
                  <span className="section-entity m-l-10"> {activeObject.name}</span>
                </div>
                {
                  isCheckboxField && populateRealValues && (
                    <div className="section-item">
                      <span className="section-entity">Value: </span>
                      <div className="section-entity">
                        <input
                          type="checkbox"
                          name="checkField"
                          className="m-l-10"
                          checked={activeObject.value}
                          onChange={handleCustomFieldCheckbox}
                        />
                      </div>
                    </div>
                  )
                }

                {
                  isTextField && populateRealValues && (
                    <div className="section-item">
                      <span className="section-entity">Value: </span>
                      <div className="section-entity">
                        <input
                          type="text"
                          name="textField"
                          className="m-l-10 control-panel-input full-width"
                          value={activeObject.value || activeObject.template}
                          onChange={(e) => handleCustomTextFieldValue(e)}
                        />
                      </div>
                    </div>
                  )
                }

              </div>
            </div>
          )
        }

        {
          activeObject.isDropdown && (
            <div className="section section-arrange">
              <div className="section-header">
                <h5 className="section-header-title">Dropdown</h5>
              </div>
              <div className="section-body">
                {
                  populateRealValues && (
                    <div className="section-item">
                      <span className="section-entity">Value: </span>
                      <div className="section-entity">
                        <select
                          className="m-l-10"
                          value={activeObject.selectedOption || 0}
                          onChange={handleDropDownSelect}>
                          <option value={0} disabled>
                            Select Option
                        </option>

                          {
                            activeObject.options.map((option, i) => {
                              return (
                                <option key={i} value={option}>{option}</option>
                              )
                            })
                          }
                        </select>
                      </div>
                    </div>
                  )
                }

                {
                  activeObject.options && (
                    <SortableList
                      items={activeObject.options}
                      onSortEnd={this.onSortEnd}
                      handleDropDownOptionInput={handleDropDownOptionInput}
                      removeDropDownOptionField={removeDropDownOptionField}
                      pressDelay={250}
                    />
                  )
                }
                <button style={{
                  margin: '10px'
                }}
                  onClick={(e) => addDropDownOptionField(e)} className="btn btn-default">Add Option</button>
              </div>
            </div>
          )
        }

        {
          activeObject.isDateField && (
            <div className="section section-arrange">
              <div className="section-header">
                <h5 className="section-header-title">Date Format</h5>
              </div>
              <div className="section-body">

                <div className="section-item">
                  <div className="section-entity title">Type</div>
                  <div className="section-entity">
                    <select onChange={(e) => handleDateFormatChange(e.target.value)} value={activeObject.dateFormat}>
                      <option value="full">Full (December 31, 2000)</option>
                      <option value="abbr">Abbr (Dec. 31, 2000)</option>
                      <option value="num">Nums (2000-12-31)</option>
                    </select>

                  </div>

                </div>

              </div>
            </div>

          )
        }
        <div className="section section-arrange">
          <div className="section-header">
            <h5 className="section-header-title">Arrange</h5>
          </div>
          <div className="section-body">

            <div className="section-item">
              <div className="section-entity title">Position</div>
              <div className="section-entity">
                <input
                  className="control-panel-input"
                  type="number"
                  value={activeObject && activeObject.left || 0}
                  onChange={(e) => {
                    handleActiveObjectLeft(e.target.value)
                  }}
                  onKeyDown={this.handleKeyDown.bind(this)}
                />
                <span>X</span>
              </div>
              <div className="section-entity">
                <input
                  className="control-panel-input"
                  type="number"
                  value={activeObject && activeObject.top || 0}
                  onChange={(e) => handleActiveObjectTop(e.target.value)}
                  onKeyDown={this.handleKeyDown.bind(this)}
                />
                <span>Y</span>
              </div>
            </div>


            <div className="section-item">
              <div className="section-entity title">Size</div>
              <div className="section-entity">
                <input
                  className="control-panel-input"
                  type="number"
                  value={activeObject && Math.round(activeObject.width)}
                  onChange={(e) => handleActiveObjectWidth(e.target.value)}
                  onKeyDown={this.handleKeyDown.bind(this)}
                />
                <span>W</span>
              </div>
              <div className="section-entity">
                <input
                  className="control-panel-input"
                  type="number"
                  value={activeObject && Math.round(activeObject.height)}
                  onChange={(e) => handleActiveObjectHeight(e.target.value)}
                  onKeyDown={this.handleKeyDown.bind(this)}
                />
                <span>H</span>
              </div>
            </div>

            <div className="section-item">
              <div className="section-entity title">Opacity</div>
              <div className="section-entity opacity">
                <input
                  className="control-panel-input opacity"
                  type="number"
                  value={activeObject && activeObject.opacity * 100}
                  step="10"
                  min="0"
                  max="100"
                  onChange={(e) => handleActiveObjectOpacity(e.target.value * 0.01)}
                  onKeyDown={this.handleKeyDown.bind(this)}

                />
                <span>%</span>
              </div>

              <div className="section-entity">
                <input
                  type="range"
                  value={activeObject && activeObject.opacity * 100}
                  min="0"
                  max="100"
                  step="10"
                  onChange={(e) => handleActiveObjectOpacity(e.target.value * 0.01)}
                />
              </div>

            </div>



            <div className="section-item">
              <div className="section-entity title"> Forward/Backward</div>
              <div style={{ marginLeft: '20px', display: 'flex' }}>
                <div className="section-entity">
                  <button
                    className="btn btn-default"
                    type="button"
                    onClick={() => moveLayerForward()}
                  >
                    <i className="glyphicon glyphicon-chevron-up"></i>
                  </button>
                </div>
                <div className="section-entity m-l-10">
                  <button
                    className="btn btn-default"
                    type="button"
                    onClick={() => moveLayerBackward()}
                  >
                    <i className="glyphicon glyphicon-chevron-down"></i>
                  </button>
                </div>
              </div>
            </div>





          </div>
        </div>
        <div className="section section-text">
          <div className="section-header">
            <h5 className="section-header-title">Text</h5>
          </div>

          <div className="section-body">
            <div className="section-item">
              <div className="section-entity title">Font</div>
              <div className="section-entity">
                <select onChange={(e) => handleTextFontFamily(e.target.value)} value={activeObject.fontFamily}>
                  {
                    fonts.map((font, index) => {
                      return (
                        <option key={index} value={font.name}>{font.name}</option>
                      )
                    })
                  }
                </select>
              </div>


            </div>

            <div className="section-item">
              <div className="section-entity title">Font Weight</div>
              <div className="section-entity">
                <select onChange={(e) => handleTextStyle(e.target.value)} value={activeObject.fontWeight}>
                  {
                    fonts.filter(f => f.name == activeObject.fontFamily)[0].
                      weights.map((w, i) => {
                        return (
                          <option key={i} value={w}>{w}</option>
                        )
                      })
                  }
                </select>
              </div>


            </div>

            <div className="section-item color-picker">
              <div className="section-entity title">Size & Color</div>
              <div className="section-entity">
                <input
                  className="control-panel-input"
                  type="number"
                  onChange={(e) => changeActiveObjectFontSize(e)}
                  onKeyDown={this.handleKeyDown.bind(this)}
                  value={activeObject && activeObject.fontSize || ""}
                />
                <span>px</span>
              </div>
              <div className="section-entity">
                <div
                  style={{
                    backgroundColor: activeObject && activeObject.fill || '#000000'
                  }}
                  onClick={() => this.setState({ isColorPickerOpened: true })} className="color-picker"></div>
                <span></span>
              </div>

              {
                this.state.isColorPickerOpened && (
                  <div className="color-picker-wrapper">
                    <SketchPicker
                      color={activeObject && activeObject.fill || '#e3e3e3'}
                      onChangeComplete={({ hex }) => changeActiveObjectFill(hex)}
                    />
                  </div>
                )
              }

            </div>

            <div className="section-item">
              <div className="section-entity title">Alignment</div>
              <div className="section-entity alignment-section">
                <div className="btn-group" role="group" aria-label="...">
                  <button
                    type="button"
                    className="btn btn-default glyphicon glyphicon-align-left"
                    onClick={() => alignTextLeft()}
                  >
                  </button>
                  <button
                    type="button"
                    className="btn btn-default glyphicon glyphicon-align-center"
                    onClick={() => alignTextCenter()}
                  >
                  </button>
                  <button
                    type="button"
                    className="btn btn-default glyphicon glyphicon-align-right"
                    onClick={() => alignTextRight()}
                  >
                  </button>
                </div>
                <span></span>
              </div>

            </div>

            {
              this.hasItalicFont() && (
                <div className="section-item">
                  <div className="section-entity title">Text Style</div>
                  <div className="section-entity alignment-section">
                    <div className="btn-group" role="group" aria-label="...">
                      <button
                        type="button"
                        className="btn btn-default glyphicon glyphicon glyphicon-italic"
                        onClick={() => makeTextStyle('italic')}
                      >
                      </button>
                      <button
                        type="button"
                        className="btn btn-default glyphicon glyphicon glyphicon-font"
                        onClick={() => makeTextStyle('normal')}
                      >
                      </button>

                    </div>
                    <span></span>
                  </div>

                </div>
              )
            }

          </div>

        </div>




      </div>
    );
  }
}

export default onClickOutside(TextControls)
