import React from "react"
import PropTypes from "prop-types"
import './basic_answer.scss'
import {capitalize, iconName} from '../../../helper'

class BasicAnswer extends React.Component {

  render () {
    const { kind } = this.props

    return (
      <div className="basic-answer">
        <span className="basic-answer-title">Answer Type:</span>
        <div className="">
          <span className={`glyphicon ${iconName(kind)}`} aria-hidden="true"></span>
          <span className="answer-type-text">{capitalize(kind)}</span>
        </div>
      </div>
    );
  }
}

export default BasicAnswer
