import React from "react"
import PropTypes from "prop-types"
import { SketchPicker } from 'react-color'
import onClickOutside from "react-onclickoutside";

import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import SortableCustomFieldList from '../SortableCustomFieldList';

import FieldsBox from "../FieldsBox";

import './basic_controls.scss'
import { read } from "fs";

window.URL = window.URL || window.webkitURL;


const SortableItem = SortableElement(({ field, i }) => {
  return (
    <li key={i} onClick={() => addCustomField(false, field.type, field.name)}>
      <a>
        <span>
          <span className={field.icon}></span>
          {field.name}
        </span>
        <div style={{ marginRight: 10 }}>
          <span className="glyphicon glyphicon-ok check"></span>
          <span
            onClick={(e) => removeCustomFieldItem(e, field.id)}
            className="glyphicon glyphicon-remove remove">
          </span>
        </div>
      </a>
    </li>
  )
});


const SortableList = SortableContainer(({ items }) => {
  return (
    <ul style={{ listStyle: 'none' }}>
      {items.map((value, index) => (
        <SortableItem
          key={`item-${value.id}`}
          index={index}
          i={index}
          value={value}
        />
      ))}
    </ul>
  );
});


class BasicControls extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      isColorPickerOpened: false,
      customFieldItems: []
    }
  }

  handleClickOutside = evt => {
    this.setState({ isColorPickerOpened: false })
  }

  getImage(e) {
    const { startImageLoading } = this.props;
    startImageLoading();
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {

      this.props.handleImageUpload(reader.result)
    }

    reader.readAsDataURL(file);

    e.target.value = "";

  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { customFields, setCustomFieldItems, updateCanvasState } = this.props;
    setCustomFieldItems(arrayMove(customFields, oldIndex, newIndex), () => {
      console.log("UPDATING G -()(()()(")
      updateCanvasState()
    })
  };


  getImageForCanvas(e) {
    let reader = new FileReader();
    let file = e.target.files[0];
    const { startImageLoading } = this.props;

    startImageLoading();
    reader.onload = () => {

      if (file) {
        var img = new Image();

        img.src = window.URL.createObjectURL(file);

        img.onload = () => {
          const ratio = img.naturalWidth / img.naturalHeight

          window.URL.revokeObjectURL(img.src);
          console.log("sisssss",)

          this.props.addImage(null, ratio, reader.result, img.naturalWidth, img.naturalHeight)

        }
      }
    }

    reader.readAsDataURL(file);
    e.target.value = null;

  }


  handleColorChange(color) {
    const { r, g, b, a } = color.rgb
    this.props.setCanvasColor(r, g, b, a)
  }

  render() {
    const {
      addText,
      addLine,
      addRect,
      defaultFields,
      customFields,
      color,
      addCustomField,
      removeCustomFieldItem,
      addSignature,
      handleCustomFieldNameChange,
      populateRealValues,
      addCustomImage,
      isCustomFieldNameTaken,
      startImageLoading,
      addCustomSignature
    } = this.props;

    return (
      <div className="basic-controls">

        <a
          onClick={() => addRect()}
          className="basic-controls-item rect">
        </a>

        <a
          onClick={() => addText()}
          className="basic-controls-item text">
          T
        </a>

        <a
          onClick={() => addLine()}
          className="basic-controls-item line">
        </a>

        <a className="basic-controls-item image">
          <span className="glyphicon glyphicon-camera"></span>
          <input
            type="file"
            name="background-image"
            id="background-image"
            onChange={this.getImageForCanvas.bind(this)}
          />
        </a>

        <div className="divider"></div>

        <a
          onClick={() => this.setState({ isColorPickerOpened: !this.state.isColorPickerOpened })}
          className="basic-controls-item color-picker"
          style={{ color: 'cadetblue' }}>
          <span className="glyphicon glyphicon-tint"></span>
        </a>

        <a className="basic-controls-item background-image">
          <span className="glyphicon glyphicon-picture"></span>
          <input
            type="file"
            name="background-image"
            id="background-image"
            onChange={this.getImage.bind(this)}
          />
        </a>

        <FieldsBox
          handleCustomFieldNameChange={handleCustomFieldNameChange}
          addCustomField={addCustomField}
          removeCustomFieldItem={removeCustomFieldItem}
          defaultFields={defaultFields}
          customFields={customFields}
          addSignature={addSignature}
          addText={addText}
          onSortEnd={this.onSortEnd}
          populateRealValues={populateRealValues}
          addCustomImage={addCustomImage}
          isCustomFieldNameTaken={isCustomFieldNameTaken}
          startImageLoading={startImageLoading}
          addCustomSignature={addCustomSignature}
        />


        {
          this.state.isColorPickerOpened && (
            <div className="color-picker-wrapper">
              <SketchPicker
                color={color}
                onChangeComplete={this.handleColorChange.bind(this)}
              />
            </div>
          )
        }

      </div>
    );
  }
}

export default BasicControls