import React from "react"
import PropTypes from "prop-types"
import {
  fabric
} from 'fabric'
import BasicControls from './BasicControls/BasicControls'
import ControlPanel from './ControlPanel/ControlPanel'
import TextControls from './TextControls/TextControls'
import { IoIosUndo, IoIosRedo } from "react-icons/io";
import moment from 'moment'
import _ from 'lodash'
import axios from 'axios';
const FontFaceObserver = require('fontfaceobserver');


var fontsData = {
  'Family A': { weight: 400, color: 'red' },
  'Family B': { weight: 400, color: 'orange' },
  'Family C': { weight: 900, color: 'yellow' },
  // Etc.
};

const fonts = [
  'Abril Fatface',
  'Alegreya',
  'Arimo',
  'Arvo',
  'Cardo',
  'Cinzel',
  'Exo 2',
  'Glegoo',
  'Gravitas One',
  'Great Vibes',
  'Josefin Sans',
  'Josefin Slab',
  'Lato',
  'Libre Baskerville',
  'Lora',
  'Merriweather',
  'Montserrat',
  'Mulish',
  'Noto Sans',
  'Nunito',
  'Old Standard TT',
  'Open Sans',
  'Open Sans Condensed',
  'Oswald',
  'Playfair Display',
  'Poppins',
  'PT Sans',
  'PT Sans Narrow',
  'PT Serif',
  'Raleway',
  'Roboto',
  'Roboto Condensed',
  'Roboto Mono',
  'Roboto Slab',
  'Slabo 27',
  'Source Sans Pro',
  'Titillium Web',
  'Ubuntu',
  'Vollkorn',
]

var observers = [];

// Make one observer for each font,
// by iterating over the data we already have

fonts.forEach(family => {
  var obs = new FontFaceObserver(family);
  observers.push(obs.load());
})

Promise.all(observers)
  .then(function (fonts) {
    fonts.forEach(function (font) {
      // Map the result of the Promise back to our existing data,
      // to get the other properties we need.
      console.log(fontsData[font.family].color);
    });
  })
  .catch(function (err) {
    console.warn('Some critical font are not available:', err);
  });


var _clipboard = null

import 'fabric-history';



fabric.Object.prototype.toObject = (function (toObject) {
  return function (propertiesToInclude) {
    propertiesToInclude = (propertiesToInclude || []).concat([
      "id",
      "fieldType",
      "customField",
      "options",
      "hasControls",
      "isDropdown",
      "selectable",
      "evented",
      "hasControls",
      "hoverCursor",
      "name",
      "template",
      "value",
      "order",
      "field_id",
      "custom_field_value_id",
      "custom_field_id",
      "backgroundType",
      "backgroundOpacity",
      "height",
      "defaultTemplate",
      "type",
      "selectedOption",
      "fontFamily",
      "src",
      "dateFormat",
      "isDateField"
    ]);
    return toObject.apply(this, [propertiesToInclude]);
  };
})(fabric.Object.prototype.toObject);



var _config = {
  // canvasState: [],
  currentStateIndex: -1,
  undoStatus: false,
  redoStatus: false,
  undoFinishedStatus: 1,
  redoFinishedStatus: 1,
  undoButton: document.getElementById('undo'),
  redoButton: document.getElementById('redo'),
};

import './fabric_canvas.scss'

const defaultFieldsList = [{
  name: 'Course Name',
  icon: 'glyphicon glyphicon-font',
  type: 'text'
},
{
  name: 'Trainer Full Name',
  icon: 'glyphicon glyphicon-font',
  type: 'text'

},
{
  name: 'Trainee Full Name',
  icon: 'glyphicon glyphicon-font',
  type: 'text'

},
{
  name: 'Company Name',
  icon: 'glyphicon glyphicon-font',
  type: 'text'

},
{
  name: 'Issue Date',
  icon: 'glyphicon glyphicon-calendar',
  type: 'date',
  value: 'issue_date'
},
{
  name: 'Expiry Date',
  icon: 'glyphicon glyphicon-calendar',
  type: 'date',
  value: 'expiry_date'

},
{
  name: 'Certification Number',
  icon: 'glyphicon glyphicon-signal',
  type: 'text',
  value: 'certification_number'

},
{
  name: 'Trainer Signature',
  icon: 'glyphicon glyphicon-pencil',
  type: 'signature'
},
// {
//   name: 'Trainee Signature',
//   icon: 'glyphicon glyphicon-pencil',
//   type: 'signature',
//   signatureType: "trainee_signature"
// },
// {
//   name: 'Employer Signature',
//   icon: 'glyphicon glyphicon-pencil',
//   type: 'signature',
//   signatureType: "employer_signature"
// },

]

class FabricCanvas extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isInPreviewMode: false,
      activeObjectType: null,
      activeObject: null,
      scale: 0.75,
      backgroundColor: '#607d8b',
      defaultFields: [],
      copied: false,
      canvasState: [],
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
      redoDisabled: true,
      undoDisabled: false,
      canvasState: [],
      currentStateIndex: -1,
      isCustomFieldNameTaken: false,
      isLoadingImage: false
    }

  }

  componentDidMount() {
    const {
      width,
      height,
      bleedValue,
      type,
      canvasJson,
      populateRealValues
    } = this.props;

    this.fabricCanvas = new fabric.Canvas();
    this.fabricCanvas.counter = 0;
    this.newLeft = 0;
    this.mods = 0;
    this.fabricCanvas.selection = false;

    var el = this.refs[type]

    initCenteringGuidelines(this.fabricCanvas);
    initAligningGuidelines(this.fabricCanvas);

    // Here we have the canvas so we can initialize fabric
    if (_.isEmpty(canvasJson)) {
      this.fabricCanvas.initialize(el, {
        width,
        height,
        backgroundColor: '#FFFFFF',
        backgroundOpacity: 100,
        preserveObjectStacking: true
      });

      const borderRect = new fabric.Rect({
        left: bleedValue,
        top: bleedValue,
        width: width - (bleedValue * 2),
        height: height - (bleedValue * 2),
        fill: 'transparent',
        stroke: '#ccc',
        strokeWidth: 2,
        selectable: false,
        evented: false,
        hasControls: false,
        hoverCursor: "default",
        name: 'borderRect'
      });

      this.fabricCanvas.add(borderRect);
      this.updateCanvasState();
    } else {
      let json = null;


      console.log("Canvas Height", height)

      this.fabricCanvas.initialize(el, { width, height });

      if (populateRealValues) {
        json = this.transformJsonForRealValues(canvasJson)
      } else {
        json = this.transformJsonForTemplateValues(canvasJson);
      }

      // Load Canvas From Existing JSON
      // console.log("JSON TO LOAD ,", JSON.stringify(json));
      this.fabricCanvas.loadFromJSON(JSON.stringify(json), () => this.fabricCanvas.renderAll(),
        (o, object) => {
          console.log("FIRST", o)
          console.log("Second", object)
          // console.log("accordion", this.fabricCanvas)

          this.loadFonts();

          object.height = o.height;
          this.fabricCanvas.field_id = JSON.parse(canvasJson).field_id;
          this.fabricCanvas.requestRenderAll();
        });

    }

    this.fabricCanvas.on('selection:created', (e) => {
      console.log("SELECTION___CREATED", e.target)
      const activeObject = this.fabricCanvas.getActiveObject();

      const { target } = e;

      if (target.type === 'activeSelection') {
        this.fabricCanvas.discardActiveObject();
      }

      if (activeObject.customField && populateRealValues) {
        activeObject.set({
          editable: false
        })
        this.fabricCanvas.requestRenderAll();
      }



      this.setState({
        activeObject: target,
        activeObjectType: target.type
      }, () => {
        console.log("ON SELECTION ACTIVE OJ", this.state.activeObject)
      })
    });

    this.fabricCanvas.on('selection:updated', (e) => {
      console.log('selection:updated')
      console.log(this.fabricCanvas.getActiveObject())
      this.setState({
        activeObject: e.target,
        activeObjectType: e.target.type
      })

    });

    this.fabricCanvas.on('object:moving', (e) => {
      var padding = 20;

      var obj = e.target;

      // if object is too big ignore
      if (obj.currentHeight > obj.canvas.height - padding * 2 ||
        obj.currentWidth > obj.canvas.width - padding * 2) {
        return;
      }
      obj.setCoords();

      // top-left corner
      if (!(!obj.customField && obj.type == 'image')) {

        if (obj.getBoundingRect().top < padding ||
          obj.getBoundingRect().left < padding) {
          obj.top = Math.max(obj.top, obj.top - obj.getBoundingRect().top + padding);
          obj.left = Math.max(obj.left, obj.left - obj.getBoundingRect().left + padding);
        }

        // bot-right corner
        if (obj.getBoundingRect().top + obj.getBoundingRect().height > obj.canvas.height - padding ||
          obj.getBoundingRect().left + obj.getBoundingRect().width > obj.canvas.width - padding) {
          obj.top = Math.min(
            obj.top,
            obj.canvas.height - obj.getBoundingRect().height + obj.top - obj.getBoundingRect().top - padding);
          obj.left = Math.min(
            obj.left,
            obj.canvas.width - obj.getBoundingRect().width + obj.left - obj.getBoundingRect().left - padding);
        }
      } else {
        if (obj.getBoundingRect().top < padding ||
          obj.getBoundingRect().left < padding) {
          obj.top = Math.max(obj.top, obj.top - obj.getBoundingRect().top);
          obj.left = Math.max(obj.left, obj.left - obj.getBoundingRect().left);
        }

        // bot-right corner
        if (obj.getBoundingRect().top + obj.getBoundingRect().height > obj.canvas.height ||
          obj.getBoundingRect().left + obj.getBoundingRect().width > obj.canvas.width) {
          obj.top = Math.min(
            obj.top,
            obj.canvas.height - obj.getBoundingRect().height + obj.top - obj.getBoundingRect().top);
          obj.left = Math.min(
            obj.left,
            obj.canvas.width - obj.getBoundingRect().width + obj.left - obj.getBoundingRect().left);
        }
      }

    });


    this.fabricCanvas.on('object:modified', (e) => {
      console.log("OBJECT:modified")

      console.log("Accctivvee oJJJ", this.state.activeObject)
      const activeObject = this.fabricCanvas.getActiveObject()
      if (populateRealValues) {


        activeObject.set({
          value: activeObject.text
        })
        this.setState({
          activeObject
        }, () => {
          this.fabricCanvas.requestRenderAll()
        })
      } else {
        activeObject.set({
          template: activeObject.text
        })
        this.setState({
          activeObject
        }, () => {
          this.fabricCanvas.requestRenderAll()
        })
      }

      this.setState({
        activeObject: e.target,
        activeObjectType: e.target.type
      }, () => {

      })
      console.log("OBJECT:modified")

      this.updateCanvasState();
    });

    this.fabricCanvas.on('object:removed', () => {
      console.log("lole")
      this.updateCanvasState();
    })

    this.fabricCanvas.on('object:added', (e) => {
      this.updateCanvasState();
    })



    this.fabricCanvas.on('object:scaling', (e) => {
      // console.log("SCALING ", e.target)
      let obj = e.target;
      var padding = 20;
      obj.setCoords();

      if (obj.type == 'image') {

        console.log("ITS IMAGE", obj.getBoundingRect().top, obj.getBoundingRect().left)
        if (obj.getBoundingRect().top < padding ||
          obj.getBoundingRect().left < padding) {
          obj.top = Math.max(obj.top, obj.top - obj.getBoundingRect().top + padding);
          obj.left = Math.max(obj.left, obj.left - obj.getBoundingRect().left + padding);
        }

        // bot-right corner
        if (obj.getBoundingRect().top + obj.getBoundingRect().height > obj.canvas.height - padding ||
          obj.getBoundingRect().left + obj.getBoundingRect().width > obj.canvas.width - padding) {
          obj.top = Math.min(
            obj.top,
            obj.canvas.height - obj.getBoundingRect().height + obj.top - obj.getBoundingRect().top - padding);
          obj.left = Math.min(
            obj.left,
            obj.canvas.width - obj.getBoundingRect().width + obj.left - obj.getBoundingRect().left - padding);
        }
      }

    });

    this.fabricCanvas.on('object:moving', (e) => {
      console.log("mooovvviing")
      console.log(this.fabricCanvas.getActiveObject().type)
      this.setState({
        activeObject: e.target,
        activeObjectType: e.target.type
      })

    });




    this.fabricCanvas.on('selection:cleared', (e) => {
      console.log("SELECTION:CLEARED")
      this.setState({
        activeObject: null,
        activeObjectType: null
      })
    });


    document.addEventListener('keydown', this.handleKeyPress.bind(this))

  }

  componentDidUpdate() {
    const { customFields, toggleRemovingCustomField, removingCustomField } = this.props;

    customFields.forEach(field => {
      this.fabricCanvas.forEachObject((o) => {
        if (field.id == o.id) {
          o.order = field.order;
        }

      });
    })

    this.fabricCanvas.forEachObject((o) => {

      if (removingCustomField && !this.contains(customFields, o.id) && o.customField) {

        this.fabricCanvas.remove(o);
        this.updateCanvasState();
        this.fabricCanvas.requestRenderAll();
        toggleRemovingCustomField();
      }
    });

  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyPress.bind(this));
  }



  loadFonts() {
    this.fabricCanvas.forEachObject(obj => {
      if (obj.type == "textbox") {
        var myfont = new FontFaceObserver(obj.fontFamily)
        myfont.load()
          .then(function () {
            // when font is loaded, use it.
            obj.set("fontFamily", obj.fontFamily);
          }).catch(function (e) {
            console.log("LOL", e)
          });
      }
    })
  }

  contains(arr, val) {
    var found = false;

    for (var i = 0; i < arr.length; i++) {
      if (arr[i].id == val) {
        found = true;
        break;
      }
    }

    return found;
  }

  transformJsonForRealValues(json) {
    const data = JSON.parse(json);

    data.objects = data.objects.map((object, i) => {
      if ((object.customField && object.value) ||
        (object.defaultTemplate && object.value)) {
        return {
          ...object,
          text: object.value
        }
      }

      return object;
    })

    return data;
  }

  transformJsonForTemplateValues(json) {
    // console.log("JSON from tra", json);
    const data = JSON.parse(json);

    // console.log('_expanded_', data)
    if (data && data.objects) {
      data.objects = data.objects.map((object, i) => {
        if (object.customField && object.value) {
          return {
            ...object,
            text: object.template
          }
        }

        return object;
      })
    }

    return data;
  }


  updateCanvasState() {
    const { canvasState, currentStateIndex } = this.state

    if ((_config.undoStatus == false && _config.redoStatus == false)) {
      var jsonData = this.fabricCanvas.toJSON([
        "id",
        "fieldType",
        "customField",
        "options",
        "hasControls",
        "isDropdown",
        "selectable",
        "evented",
        "hasControls",
        "hoverCursor",
        "name",
        "template",
        "value",
        "order",
        "field_id",
        "custom_field_value_id",
        "custom_field_id",
        "backgroundType",
        "backgroundOpacity",
        "height",
        "defaultTemplate",
        "type",
        "selectedOption",
        "src",
        "dateFormat",
        "isDateField",
        "signatureField",
        "signatureType"
      ]);

      if (currentStateIndex < canvasState.length - 1) {
        let indexToBeInserted = currentStateIndex + 1;
        // console.log("SHEMOVVVVVViss")

        this.setState({
          canvasState: this.state.canvasState.map((state, i) => {
            if (i == indexToBeInserted) {
              return JSON.stringify(jsonData);
            }
            return state;
          })
        }, () => {
          var numberOfElementsToRetain = indexToBeInserted + 1;
          // _config.canvasState = _config.canvasState.splice(0, numberOfElementsToRetain);
          let newCanvasState = [...this.state.canvasState];
          this.setState({
            canvasState: newCanvasState.splice(0, numberOfElementsToRetain)
          })
        })
        // _config.canvasState[indexToBeInserted] = canvasAsJson;
      } else {
        // _config.canvasState.push(canvasAsJson);
        // console.log("ELSE", this.state.canvasState)
        this.setState({
          canvasState: this.state.canvasState.concat(JSON.stringify(jsonData))
        })
      }
      // currentStateIndex = canvasState.length - 1;
      this.setState({
        currentStateIndex: canvasState.length - 1
      })
      if ((currentStateIndex == canvasState.length - 1) && currentStateIndex != -1) {
        // _config.redoButton.disabled= "disabled";
        this.setState({
          redoDisabled: true
        })
      }

    }
  }

  undo() {
    this.fabricCanvas.undo();
    this.fabricCanvas.undo();
  }

  redo() {
    console.log("REDO")
    this.fabricCanvas.redo();
    this.fabricCanvas.redo();
  }

  updateModifications(savehistory) {
    if (savehistory === true) {
      var jsonData = this.fabricCanvas.toJSON([
        "id",
        "fieldType",
        "customField",
        "options",
        "hasControls",
        "isDropdown",
        "hoverCursor",
        "name",
        "template",
        "value",
        "order",
        "field_id",
        "custom_field_value_id",
        "custom_field_id",
        "backgroundType",
        "backgroundOpacity",
        "height",
        "defaultTemplate",
        "type",
        "selectedOption",
        "signatureField",
        "signatureType"
      ]);
      this.setState({
        canvasState: this.state.canvasState.concat(JSON.stringify(jsonData))
      })
    }
  }

  getCanvas() {
    this.fabricCanvas.loadFromJSON(this.fabricCanvas.toObject(), function () {
      this.fabricCanvas.renderAll();
    }, function (o, object) {
      console.log(o, object)
      // object.height = o.height;
      // this.requestRenderAll();
    })
  }

  previewCanvas(e) {
    e.preventDefault();

    this.setState({
      isInPreviewMode: true,
    }, () => {
      this.fabricCanvas.selection = false;
      this.fabricCanvas.forEachObject(o => {
        o.selectable = false;
        o.hoverCursor = 'default';
        if (o.needsPreviewControl) {
          o.text = o.previewValue
        }
        this.fabricCanvas.discardActiveObject();
        this.fabricCanvas.requestRenderAll();
      })
    })
  }


  editCanvas(e) {
    console.log("EDIt MODEEE")
    e.preventDefault();

    this.setState({
      isInPreviewMode: false
    }, () => {
      this.fabricCanvas.selection = true;
      this.fabricCanvas.forEachObject(o => {
        console.log("SHEMODIS", o)
        if (o.name == 'borderRect') {
          console.log("OPLAAA")
          o.selectable = false;
          o.evented = false;
        } else {
          o.selectable = true;
          o.hoverCursor = 'pointer'
          if (o.customField) {
            o.text = o.template
            o.previewValue = "";
          }
          if (o.customField && o.type == "image") {
            o.setSrc(o.template, () => {
              o.scaleX = 1;
              o.scaleY = 1;

              this.fabricCanvas.requestRenderAll();
            });
          }
          if (o.needsPreviewControl) {
            console.log('fieldtype ', o.fieldType)
            if (o.fieldType == "checkbox" || o.type == "checkbox") {
              console.log("MILANO")
              o.set({
                text: "☐"
              })
            } else {
              o.set({
                text: `[[ ${o.defaultValue || o.value} ]]`
              })

            }
          }

        }



      });
      this.fabricCanvas.renderAll();
    })
  }

  clearCanvas(e) {
    console.log("CLEARING CANVAS")
    e.preventDefault();

    const {
      width,
      height
    } = this.props


    this.fabricCanvas.clear();
    this.fabricCanvas.initialize(this.refs[this.props.type], {
      width,
      height,
      backgroundColor: '#FFFFFF'
    });
    this.fabricCanvas.add(new fabric.Rect({
      left: 20,
      top: 20,
      width: 460,
      height: 280,
      fill: 'transparent',
      stroke: '#ccc',
      strokeWidth: 2,
      selectable: false,
      hoverCursor: "default"
    }));
    this.newleft = 0;
  }

  getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  addSignature(value, needsPreviewControl) {

    const id = Date.now()

    fabric.Image.fromURL(this.props.img_src, (myImg) => {

      myImg.scaleToWidth(150);
      myImg.scaleToHeight(70);

      var img1 = myImg.set({
        left: this.getRandomNumber(0, 300),
        top: this.getRandomNumber(0, 300),
        lockUniScaling: true,
        id,
        fieldType: 'signature',
        defaultTemplate: true
      });


      this.fabricCanvas.add(img1);
      this.updateModifications(true);
      this.fabricCanvas.counter++;
      this.newleft += 100;
    });
  }

  addCustomSignature(signatureType) {
    const { trainee_signature_img, employer_signature_img } = this.props;
    const id = Date.now();
    const imgSrc = (signatureType == 'trainee_signature') ?
      trainee_signature_img : employer_signature_img;

    fabric.Image.fromURL(imgSrc, (img) => {

      img.scaleToWidth(150);
      img.scaleToHeight(70);

      let newImg = img.set({
        left: this.getRandomNumber(0, 300),
        top: this.getRandomNumber(0, 300),
        lockUniScaling: true,
        id,
        fieldType: 'signature',
        defaultTemplate: true,
        custom_field_id: null,
        signatureField: true,
        signatureType
      });

      this.fabricCanvas.add(newImg);
      this.updateModifications(true);
      this.updateCanvasState();
      this.fabricCanvas.counter++;
      this.newleft += 100;
    });
  }

  addText(val, needsPreviewControl, customId, isDateField, fieldVal, isDropdown, isCstText) {
    const { addToClonedObjects } = this.props;
    let textLabel = val ? `[[ ${val} ]]` : 'Double click to edit text'
    const id = Date.now()

    let text = new fabric.Textbox(textLabel, {
      fontFamily: 'Open Sans',
      fontSize: 17,
      width: 250,
      height: 25,
      left: this.getRandomNumber(30, 250),
      top: this.getRandomNumber(30, 200),
      objecttype: 'text',
      fill: '#000000',
      id: customId ? customId : id,
      lockScalingY: true,
      isDateField,
      isDropdown,
      defaultValue: val,
      order: null,
      previewValue: textLabel,
      needsPreviewControl,
      fieldType: isDropdown ? 'dropdown' : 'text',
      template: textLabel,
      custom_field_id: null,
      customField: isCstText ? true : false
    });

    text.options = isDropdown ? [] : null;
    text.name = val;
    text.template = textLabel;
    text.value = null;

    if (needsPreviewControl) {
      if (fieldVal == 'expiry_date') {
        text.fieldType = 'expiry_date'
      } else if (fieldVal == 'issue_date') {
        text.fieldType = 'issue_date'
      } else if (fieldVal == "certification_number") {
        text.fieldType = 'certification_number'
      } else {
        text.fieldType = 'text'
      }

      text.defaultTemplate = true
    }

    if (isDropdown) {
      text.customField = true;
      text.selectedOption = null;
    }

    addToClonedObjects(text, () => {
      this.fabricCanvas.add(text);

      this.updateModifications(true);
      this.fabricCanvas.counter++;
      this.newleft += 100;

      if (needsPreviewControl) {

        this.setState({
          defaultFields: this.state.defaultFields.concat({
            id: customId ? customId : id,
            value: val,
            isDateField,
            isDropdown,
            defaultValue: `[[ ${val} ]]`,
            dateFormat: 1,
            customField: true,
            fieldType: 'text',
            fieldType: isDropdown ? 'dropdown' : 'text',
          })
        })

      }
    });

    this.updateCanvasState();


  }


  addLine() {
    this.fabricCanvas.add(new fabric.Line([50, 200, 200, 200], {
      left: this.getRandomNumber(20, 300),
      top: this.getRandomNumber(20, 200),
      stroke: 'black',
      strokeWidth: 3
    }));
    this.updateModifications(true);
    this.fabricCanvas.counter++;
    this.newleft += 100;

  }

  addBox() {
    const rect = new fabric.Rect({
      left: this.getRandomNumber(20, 300),
      top: this.getRandomNumber(20, 200),
      width: 50,
      height: 50,
      newWidth: 50,
      newHeight: 50,
      fill: 'transparent',
      stroke: 'black',
      strokeWidth: 3,
      lockScalingFlip: true,
    })

    rect.setControlsVisibility({
      mt: false, // middle top disable
      ml: false, // middle left
    });

    this.fabricCanvas.add(rect);
  }

  updateImage(src, ratio, naturalWidth, naturalHeight) {
    const { course_id, card_id, updateClonedCustomFieldImage } = this.props;
    const activeObject = this.fabricCanvas.getActiveObject();
    const oldWidth = activeObject.width;
    const oldHeight = activeObject.height;

    let payload = { field: {} };

    if (activeObject.customField) {
      payload.field.image_base64 = src;
      payload.field.custom_field = true;
      payload.field.custom_field_id = activeObject.custom_field_id;
      payload.field.custom_field_value_id = activeObject.custom_field_value_id;
      payload.field.name = activeObject.name;
      payload.field.course_id = course_id;
      payload.field.card_id = card_id;
    } else {
      payload.field.image_base64 = src;
      payload.field.field_id = activeObject.field_id;
    }

    axios.post('/card_designer_images', payload)
      .then(res => {
        if (activeObject.customField) {

          activeObject.setSrc(res.data.result.value, () => {
            console.log("NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN")
            activeObject.custom_field_value_id = res.data.result.custom_field_value_id;
            activeObject.custom_field_id = res.data.result.custom_field_id;
            activeObject.width = oldWidth;
            activeObject.height = oldHeight;
            this.updateCanvasState();
            this.fabricCanvas.requestRenderAll();

            updateClonedCustomFieldImage(
              activeObject.name,
              res.data.result.value,
              oldWidth,
              oldHeight
            )

          });
        } else {
          activeObject.setSrc(res.data.result.value, () => {
            console.log("NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN")

            console.log("ACCC ", activeObject.width);
            console.log("OLD ", oldWidth);

            activeObject.width = oldWidth;
            activeObject.height = oldHeight;
            activeObject.scaleX = this.fabricCanvas.width * ratio / naturalWidth / 4;
            activeObject.scaleY = this.fabricCanvas.width / naturalHeight / 4;

            this.updateCanvasState();
            this.fabricCanvas.requestRenderAll();
          });
        }

      })
      .catch(err => console.log(err))

  }

  updateClonedCustomFieldImage(name, src, width, height) {
    this.fabricCanvas.forEachObject((object) => {
      console.log('this props type ', this.props.type)
      console.log('this props name ', object.name, name)

      if (object.name === name) {
        console.log("JJJJJ")
        console.log("Canvas Name - ", this.props.type);

        object.setSrc(src, () => {
          object.width = width;
          object.height = height;

          this.updateCanvasState();
          this.fabricCanvas.requestRenderAll();
        });
      }
    });

  }

  addCustomPlaceholderImage(name, id) {
    const { addToClonedObjects } = this.props;
    const url = `https://via.placeholder.com/150x100?text=${name}`;

    new fabric.Image.fromURL(url, (myImg) => {
      let img1 = null

      img1 = myImg.set({
        left: this.getRandomNumber(20, 300),
        top: this.getRandomNumber(20, 200),
        width: 150,
        height: 100,
        lockScalingFlip: true,
        lockSkewingX: true,
        lockSkewingY: true,
        id,
        customImage: true,
        fieldType: 'image',
        order: null,
        customField: true,
        custom_field_value_id: null,
        name,
        scalingAffectsSizeProp: false,
        src: myImg._originalElement.currentSrc,
        template: url
      });
      img1.setControlsVisibility({
        mt: false, // middle top disable
        ml: false, // middle left
      });

      addToClonedObjects(img1, () => {
        this.fabricCanvas.add(img1);
        console.log("LELELE ", img1);
        // this.updateModifications(true);s
        this.updateCanvasState();
        this.fabricCanvas.counter++;
        this.newleft += 100;
      });

    })
  }


  addCustomImage(image_base64, ratio, originalWidth, originalHeight) {
    const { course_id, card_id, customFieldName, updateCustomFields, populateRealValues } = this.props;
    const id = Date.now();


    // console.log("csttttt ", customFieldName);


    axios.post('/card_designer_images', {
      field: {
        image_base64,
        course_id,
        card_id,
        name: customFieldName,
        custom_field_id: id,
      }
    })
      .then((res) => {
        console.log("ress", res)
        const imgSrc = res.data.result.value

        fabric.Image.fromURL(imgSrc, (myImg) => {
          let img1 = null

          img1 = myImg.set({
            left: this.getRandomNumber(20, 300),
            top: this.getRandomNumber(20, 200),
            lockScalingFlip: true,
            scaleX: this.fabricCanvas.width * ratio / originalWidth / 4,
            scaleY: this.fabricCanvas.width / originalHeight / 4,
            lockSkewingX: true,
            lockSkewingY: true,
            id,
            customImage: true,
            fieldType: 'image',
            order: null,
            customField: true,
            name: customFieldName,
            field_id: res.data.result.field_id,
            scalingAffectsSizeProp: false
          });
          img1.setControlsVisibility({
            mt: false, // middle top disable
            ml: false, // middle left
          });

          img1.scaleToHeight(img1.height || 100);
          img1.scaleToWidth(img1.width || 250);

          this.fabricCanvas.add(img1);
          this.updateModifications(true);
          this.fabricCanvas.counter++;
          this.newleft += 100;
          updateCustomFields(customFieldName, id, "image");
          this.stopImageLoading();

        });

      })
      .catch((err) => console.log(err));
  }

  addImage(id, ratio, src, originalWidth, originalHeight, customImgName, isCustomField) {
    console.log("Adding Image", ratio, originalWidth, originalHeight)
    const { populateRealValues } = this.props;

    const imgWidth = 100 * Number(ratio)
    const image_base64 = src;

    if (isCustomField) {
      this.addCustomPlaceholderImage(customImgName, id);
    } else {
      axios.post('/card_designer_images', {
        field: {
          image_base64
        }
      })
        .then((res) => {
          const imgSrc = res.data.result.value || `https://via.placeholder.com/150x100?text=${customImgName}`

          fabric.Image.fromURL(imgSrc, (myImg) => {
            let img1 = null
            img1 = myImg.set({
              left: this.getRandomNumber(20, 300),
              top: this.getRandomNumber(20, 200),
              lockUniScaling: true,
              // width: 250,
              // height: 100,
              // scaleX: this.fabricCanvas.width * ratio / originalWidth / 4,
              // scaleY: this.fabricCanvas.width / originalHeight / 4,
              id,
              scalingAffectsSizeProp: false,
              mainImage: true
            });

            img1.setControlsVisibility({
              mt: false, // middle top disable
              ml: false, // middle left
            });

            img1.scaleToWidth(250);
            img1.scaleToHeight(100);
            img1.field_id = res.data.result.field_id;
            console.log("img1111", img1)

            this.fabricCanvas.add(img1);
            this.updateModifications(true);
            this.fabricCanvas.counter++;
            this.newleft += 100;
            this.stopImageLoading();
          });

        })
        .catch((err) => console.log(err));

    }




    // console.log("img srouc", imgSrc)

  }



  isDeleteKey(key) {
    if ((key == 46) || (key == 8)) return true

    return false
  }

  isArrowKeys(key) {
    if ((key == 37) || (key == 38) || (key == 39) || (key == 40)) return true

    return false
  }

  moveElement(key) {

    const activeObject = this.fabricCanvas.getActiveObject()

    if (!activeObject) return null

    switch (key) {
      case 37:
        activeObject.left = activeObject.left - 2
        break
      case 38:
        activeObject.top = activeObject.top - 2
        break
      case 39:
        activeObject.left = activeObject.left + 2
        break
      default:
        activeObject.top = activeObject.top + 2
    }

    this.setState({
      activeObject
    }, () => {
      this.fabricCanvas.requestRenderAll()
    })


  }

  handleKeyPress(e) {
    const localName = e.target.localName;
    console.log("<><><><>")

    if ((localName == 'input') || localName == 'textarea') {
      e.stopPropagation();
    } else if (this.isDeleteKey(e.which)) {
      return this.removeActiveElement()
    }


    return;
  }

  removeActiveElement() {
    const { removeActiveCustomFieldItem } = this.props;
    const activeObject = this.fabricCanvas.getActiveObject();
    console.log("DELETING ")
    let count = 0;
    this.fabricCanvas.forEachObject(obj => {
      if (obj.name && (obj.name == activeObject.name)) {
        count += 1;
      }
    });

    if (count == 1) {
      removeActiveCustomFieldItem(activeObject.id, () => {
        this.setState({
          activeObject: null,
          defaultFields: this.state.defaultFields.filter(field => field.id != activeObject.id)
        }, () => {
          this.fabricCanvas.remove(this.fabricCanvas.getActiveObject());
          this.fabricCanvas.requestRenderAll();
        })
      })
    } else {
      this.setState({
        activeObject: null,
        defaultFields: this.state.defaultFields.filter(field => field.id != activeObject.id)
      }, () => {
        this.fabricCanvas.remove(this.fabricCanvas.getActiveObject());
        this.fabricCanvas.requestRenderAll();
      })
    }

  }

  handleImageUpload(img) {
    const { course_id, card_id, type } = this.props;


    const payload = {
      field: {
        image_base64: img,
        field_id: this.fabricCanvas.field_id || null,
        course_id,
        card_id,
        // placement: type
      }
    };

    console.log("Payload ", payload);

    this.setState({
      background: 'image'
    }, () => {
      axios.post('/card_designer_images', payload)
        .then((res) => {
          console.log("reesss", res)
          fabric.Image.fromURL(res.data.result.value, (img) => {
            img.scaleX = this.fabricCanvas.width / img.width;
            img.scaleY = this.fabricCanvas.height / img.height;
            this.fabricCanvas.backgroundType = "image";
            this.fabricCanvas.setBackgroundImage(img);
            this.fabricCanvas.field_id = res.data.result.field_id;
            console.log('olee', this.fabricCanvas);
            this.updateCanvasState();
            this.fabricCanvas.requestRenderAll();
          });
          this.stopImageLoading();
        })
        .catch(err => console.log(err))
    })
  }

  setCanvasColor(r, g, b, a) {

    const color = `rgba(${r}, ${g}, ${b}, ${a})`


    this.setState({
      backgroundColor: color,
      background: 'color',
      r,
      g,
      b,
      a
    }, () => {
      this.fabricCanvas.backgroundColor = color;
      this.fabricCanvas.backgroundType = "color";
      this.fabricCanvas.backgroundOpacity = 100;
      this.fabricCanvas.setBackgroundImage(null);
      this.updateCanvasState();
      this.fabricCanvas.requestRenderAll();
    })
  }

  changeActiveObjectFill(color) {
    console.log("color ", activeObject)
    const activeObject = this.fabricCanvas.getActiveObject()


    if (activeObject.type != 'textbox') {
      activeObject.set({
        stroke: color
      })
    } else {
      activeObject.set({
        fill: color
      })
    }
    this.setState({
      activeObject
    }, () => {
      this.updateCanvasState();
      this.fabricCanvas.requestRenderAll();
    })

  }


  handleActiveObjectOpacity(opacity) {
    const activeObject = this.fabricCanvas.getActiveObject()

    activeObject.set({
      opacity: Number(opacity)
    })
    this.setState({
      activeObject
    }, () => {
      this.updateCanvasState();
      this.fabricCanvas.requestRenderAll()
    })

  }

  handleActiveObjectStrokeWidth(value) {
    const activeObject = this.fabricCanvas.getActiveObject()

    activeObject.set({
      strokeWidth: Number(value)
    })
    this.setState({
      activeObject
    }, () => {
      this.updateCanvasState();
      this.fabricCanvas.requestRenderAll()
    })
  }

  handleActiveObjectLeft(value) {
    const activeObject = this.fabricCanvas.getActiveObject()


    console.log('aaaactiiiveoobject', activeObject)

    activeObject.set({
      left: Number(value)
    })
    this.setState({
      activeObject
    }, () => {
      this.updateCanvasState();
      this.fabricCanvas.requestRenderAll()
    })
  }

  handleActiveObjectTop(value) {
    const activeObject = this.fabricCanvas.getActiveObject()

    activeObject.set({
      top: Number(value)
    })
    this.setState({
      activeObject
    }, () => {
      this.updateCanvasState();
      this.fabricCanvas.requestRenderAll()
    })
  }

  handleActiveObjectWidth(value) {
    const activeObject = this.fabricCanvas.getActiveObject()

    activeObject.set({
      // width: Number(value)
      scaleX: Number(value) / activeObject.width,
      scaleY: Number(value) / activeObject.width
    })
    this.setState({
      activeObject
    }, () => {
      this.updateCanvasState();
      this.fabricCanvas.requestRenderAll()
    })
  }

  handleActiveObjectHeight(value) {
    const re = /^[0-9\b]+$/;


    const activeObject = this.fabricCanvas.getActiveObject()
    const height = Number(value.replace(/^0+/, ''))
    console.log("HEIGHT", height)

    activeObject.set({
      scaleX: Number(height) / activeObject.height,
      scaleY: Number(height) / activeObject.height
    });

    this.setState({
      activeObject
    }, () => {
      console.log("opaaa", activeObject)
      this.updateCanvasState();
      this.fabricCanvas.requestRenderAll();
    })
  }

  changeActiveObjectFontSize(e) {
    const activeObject = this.fabricCanvas.getActiveObject()

    activeObject.set({
      fontSize: Number(e.target.value)
    })
    this.setState({
      activeObject
    }, () => {
      this.updateCanvasState();
      this.fabricCanvas.requestRenderAll()
    })

  }

  scaleUp() {
    const { screenWidth, screenHeight, scale } = this.state

    if (scale < 2) {
      this.setState({
        scale: scale + 0.25,
        screenWidth: screenWidth * 1.25,
        screenHeight: screenHeight * 1.25
      })
    }
  }
  scaleDown() {
    const { screenWidth, screenHeight, scale } = this.state

    if (scale > 0.25) {
      this.setState({
        scale: scale - 0.25,
        screenWidth: screenWidth * 1.25,
        screenHeight: screenHeight * 1.25
      })
    }
  }

  isZoomAtMin() {
    return this.state.scale == 0.25
  }
  isZoomAtMax() {
    return this.state.scale == 2
  }

  renderZoomInPercentage(scale) {
    return `${scale * 100}%`
  }

  resetZoom() {
    this.setState({
      scale: 1,
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight
    })
  }

  customFieldWithNameExists(name) {
    const { customFields } = this.props;

    return customFields.some(field => field.name == name);
  }

  isTypeSeparator(type) {
    return type === "separator";
  }

  addSeparator(id) {
    const { addToClonedObjects } = this.props;

    console.log("Adding Separator! ...");

    let text = new fabric.Textbox("Separator", {
      left: this.getRandomNumber(30, 250),
      top: this.getRandomNumber(30, 200),
      objecttype: 'text',
      selectable: false,
      evented: false,
      hasControls: false,
      fill: 'transparent',
      id,
      order: null,
      fieldType: "separator",
      custom_field_id: null,
      customField: true,
      separator: true,
      value: "",
      text: "",
    });

    console.log("Separator Object ", text);

    addToClonedObjects(text, () => {
      this.fabricCanvas.add(text);
      this.updateModifications(true);
      this.fabricCanvas.counter++;
      this.newleft += 100;
      this.updateCanvasState();
      this.fabricCanvas.requestRenderAll();
    });
  }

  addCustomField(isCreatedFirst, type, val, fieldId) {

    const { addToClonedObjects, clonedObjects } = this.props;
    const clonedObjectsTranformed = clonedObjects.map(obj => {
      console.log("OOOOOO ", obj);
      if (obj.type == "image") {
        return new fabric.Image(obj, {
          ...obj,
          id: obj.id,
          name: obj.name,
          src: obj.src
        });
      }
      return obj;
    })

    console.log("clonedObjectsTranformed", clonedObjects);

    if (fieldId) {
      console.log("Cloning ")
      const canvasObjects = [];

      this.fabricCanvas.forEachObject(o => canvasObjects.push(o));
      console.log("canvasObjc", canvasObjects)

      let objects = _.unionBy(canvasObjects, clonedObjectsTranformed, "id");

      objects
        .some(obj => {
          console.log('obj.id', obj)
          console.log('fieldId', fieldId)

          console.log(obj.id == fieldId)
          if (obj.id == fieldId) {
            console.log('TO CLONE ', obj)
            obj.clone(clone => {
              clone.set({
                left: this.getRandomNumber(20, 300),
                top: this.getRandomNumber(20, 200),
                id: fieldId,
                customField: true,
                name: obj.name,
                value: obj.value,
                template: obj.template,
                fieldType: obj.fieldType,
                options: obj.options,
                isDropdown: obj.isDropdown,
                selectedOption: obj.selectedOption,
                customImage: obj.customImage,
                field_id: obj.field_id,
                custom_field_id: obj.custom_field_id,
                custom_field_value_id: obj.custom_field_value_id,
                course_id: obj.course_id,
                card_id: obj.card_id,
              })
              console.log("cloNED ", clone)
              addToClonedObjects(clone, () => {
                this.fabricCanvas.add(clone);
                this.updateCanvasState();
                this.fabricCanvas.requestRenderAll();
              });
            });

            return true;
          }

        });


    } else {
      console.log("Adding New Custom Field");
      const { updateCustomFields, customFieldName } = this.props;
      const name = val ? val : customFieldName;
      const id = Date.now();

      if (this.isTypeSeparator(type)) {
        updateCustomFields("Separator", id, "separator");
        this.addSeparator(id);
      } else {
        if (this.customFieldWithNameExists(customFieldName)) {
          this.setState({
            isCustomFieldNameTaken: true
          })
        } else {
          if (type == 'text') {
            if (isCreatedFirst) {
              updateCustomFields(name, id)
              this.addText(name, false, id, null, null, null, true)
            } else {
              return this.addText(name, false, id, null, null, null, true)
            }

          } else if (type === 'dropdown') {
            if (isCreatedFirst) {
              updateCustomFields(name, id, type)
              this.addText(name, false, id, false, null, true)
            } else {
              return this.addText(name, false, id, false, null, true)
            }
          } else if (type === 'image') {
            if (isCreatedFirst) {
              updateCustomFields(name, id, type);
              return this.addImage(id, null, null, null, null, name, true)
            } else {
              return this.addImage(id, null, null, null, null, name, true)
            }
          } else if (type === "checkbox") {
            if (isCreatedFirst) {
              updateCustomFields(name, id, "checkbox")
              this.addCheckbox(name, id)
            } else {
              return this.addCheckbox(name, id)
            }
          }
          this.setState({
            isCustomFieldNameTaken: false
          })

        }

      }

    }

  }

  addCheckbox(name, id) {
    const { addToClonedObjects } = this.props;
    console.log("olee")
    let textLabel = '☐';

    let text = new fabric.Textbox(textLabel, {
      fontFamily: 'Open Sans',
      fontSize: 25,
      width: 20,
      left: this.getRandomNumber(30, 250),
      top: this.getRandomNumber(30, 200),
      objecttype: 'text',
      fill: '#000000',
      id,
      lockScalingY: true,
      defaultValue: textLabel,
      customField: true,
      order: null,
      previewValue: '',
      needsPreviewControl: true,
      fieldType: 'checkbox',
      name,
      value: false,
      template: `[[ ${textLabel} ]]`,
      custom_field_id: null,
      previewValue: '☐'
    })

    addToClonedObjects(text, () => {
      this.fabricCanvas.add(text);
      this.updateCanvasState();
      this.updateModifications(true);
      this.fabricCanvas.counter++;
      this.newleft += 100;
    });


  }

  removeCustomFieldItem(e, fieldId) {
    const { removeCustomFieldItem } = this.props;
    e.stopPropagation()

    removeCustomFieldItem(fieldId, () => {
      this.setState({
        defaultFields: this.state.defaultFields.filter(field => field.id != fieldId)
      }, () => {
        this.fabricCanvas.forEachObject(obj => {
          if (obj.id && (obj.id == fieldId)) {
            this.fabricCanvas.remove(obj)
            this.updateCanvasState();
            this.fabricCanvas.requestRenderAll()
          }
        })
      })
    })

  }


  alignTextLeft() {
    const activeObject = this.fabricCanvas.getActiveObject()

    activeObject.set({
      textAlign: 'left'
    })
    this.setState({
      activeObject
    }, () => {
      this.updateCanvasState();
      this.fabricCanvas.requestRenderAll()
    })
  }

  alignTextCenter() {
    const activeObject = this.fabricCanvas.getActiveObject()

    activeObject.set({
      textAlign: 'center'
    })
    this.setState({
      activeObject
    }, () => {
      this.updateCanvasState();
      this.fabricCanvas.requestRenderAll()
    })


  }

  alignTextRight() {
    const activeObject = this.fabricCanvas.getActiveObject()

    activeObject.set({
      textAlign: 'right'
    })
    this.setState({
      activeObject
    }, () => {
      this.updateCanvasState();
      this.fabricCanvas.requestRenderAll()
    })


  }

  makeTextStyle(value) {
    const activeObject = this.fabricCanvas.getActiveObject()

    if (value == 'italic') {
      activeObject.set({
        fontStyle: 'italic'
      })
    } else {
      activeObject.set({
        fontStyle: 'normal'
      })
    }


    this.setState({
      activeObject
    }, () => {
      this.updateCanvasState();
      this.fabricCanvas.requestRenderAll()
    })

  }

  handleTextFontFamily(value) {
    const activeObject = this.fabricCanvas.getActiveObject()

    activeObject.set({
      fontFamily: value
    })
    this.setState({
      activeObject
    }, () => {
      this.updateCanvasState();
      this.fabricCanvas.requestRenderAll();
    })
  }

  handleTextStyle(value) {
    const activeObject = this.fabricCanvas.getActiveObject()

    activeObject.set({
      fontWeight: value
    })
    this.setState({
      activeObject
    }, () => {
      this.updateCanvasState();
      this.fabricCanvas.requestRenderAll()
    })
  }


  copy() {
    const activeObject = this.fabricCanvas.getActiveObject();
    activeObject.clone((cloned) => {
      _clipboard = cloned;
      _clipboard.name = activeObject.name;
      _clipboard.selectedOption = activeObject.selectedOption;
      _clipboard.options = activeObject.options;
      _clipboard.isDropdown = activeObject.isDropdown;
      _clipboard.fieldType = activeObject.fieldType;
      _clipboard.customField = activeObject.customField;
      this.setState({
        copied: true
      })
    });
  }

  paste() {
    // clone again, so you can do multiple copies.
    console.log("_clipboard ", _clipboard)
    _clipboard.clone((clonedObj) => {
      this.fabricCanvas.discardActiveObject();
      clonedObj.set({
        left: clonedObj.left + 10,
        top: clonedObj.top + 10,
        evented: true,
        name: _clipboard.name,
        selectedOption: _clipboard.selectedOption,
        options: _clipboard.options,
        isDropdown: _clipboard.isDropdown,
        fieldType: _clipboard.fieldType,
        customField: _clipboard.customField
      });
      if (clonedObj.type === 'activeSelection') {
        // active selection needs a reference to the canvas.
        clonedObj.canvas = canvas;
        clonedObj.forEachObject((obj) => {
          this.fabricCanvas.add(obj);
        });
        // this should solve the unselectability
        clonedObj.setCoords();
      } else {
        this.fabricCanvas.add(clonedObj);
      }
      _clipboard.top += 10;
      _clipboard.left += 10;
      this.fabricCanvas.setActiveObject(clonedObj);
      this.fabricCanvas.requestRenderAll();
      this.setState({
        copied: false
      })
    });
  }

  handleDateFormatChange(value) {
    console.log("olee ", value)
    const activeObject = this.fabricCanvas.getActiveObject()


    activeObject.previewValue = this.renderDateFormatByType(value, activeObject.previewValue)

    this.setState({
      defaultFields: this.state.defaultFields.map(field => {
        if (field.id == activeObject.id) {
          return {
            ...field,
            dateFormat: value
          }
        }

        return { ...field }
      })
    }, () => {
      activeObject.set({
        dateFormat: value
      })
      this.setState({
        activeObject
      }, () => {
        this.updateCanvasState();
        this.fabricCanvas.requestRenderAll()
      })

    })
  }

  changePreviewImage(url, ratio, naturalWidth, naturalHeight, id) {
    this.fabricCanvas.forEachObject(obj => {

      if (obj.id == id) {
        obj.setSrc(url, () => {
          obj.scaleX = this.fabricCanvas.width * ratio / naturalWidth / 4;
          obj.scaleY = this.fabricCanvas.width / naturalHeight / 4;
          this.fabricCanvas.requestRenderAll();

        });
      }
      // })
    })
  }


  changePreviewText(id, value) {
    const { updateClonedObject } = this.props;

    this.setState({
      defaultFields: this.state.defaultFields.map(field => {
        console.log('field id  ', field.id)
        console.log(' id  ', id)
        console.log(' id  ', value)


        if (field.id == id) {
          return {
            ...field,
            value
          }
        }

        return { ...field }
      })
    }, () => {
      this.fabricCanvas.forEachObject(obj => {
        console.log('Mialno', obj.id)
        console.log('Milani ', obj)

        if (obj.id == id) {
          console.log("ROGER FEDERER", value)
          obj.set({
            text: value,
            previewValue: value
          })
          updateClonedObject(id, value);
          // this.fabricCanvas.remove(obj)
          this.fabricCanvas.requestRenderAll()
        }
      })
    })
  }


  changePreviewDate(id, value) {
    console.log('COOMInininingignignignigng')
    this.setState({
      defaultFields: this.state.defaultFields.map(field => {
        if (field.id == id) {
          return {
            ...field,
            value: new Date(value)
          }
        }

        return { ...field }
      })
    }, () => {
      this.fabricCanvas.forEachObject(obj => {
        console.log(obj.id)
        console.log(obj.id)

        if (obj.id == id) {
          console.log("ROGER FEDERER", this.renderDateFormatByType(obj.dateFormat, value))
          obj.set({
            text: this.renderDateFormatByType(obj.dateFormat, value),
            previewValue: this.renderDateFormatByType(obj.dateFormat, value)
          })
          // this.fabricCanvas.remove(obj)
          this.fabricCanvas.requestRenderAll()

        }
      })
    })

  }

  changePreviewForCheckbox(id, val) {
    this.fabricCanvas.forEachObject(obj => {

      if (obj.id == id) {
        console.log("objecccccc ", obj);
        const newVal = obj.text == '☐' ? '☑' : '☐';
        obj.set({
          text: newVal,
          previewValue: newVal,
        })
        this.fabricCanvas.requestRenderAll();
      }
    });
  }

  changePreviewDropDown(id, val) {
    console.log("BBBBBB ", val)
    this.fabricCanvas.forEachObject(obj => {

      if (obj.id == id) {
        obj.set({
          text: val,
          previewValue: val,
        })
        this.fabricCanvas.requestRenderAll();
      }
    });
  }

  renderDateFormatByType(type, date) {


    console.log("JAAAAKEEEE ", date)

    if (type == 2) {
      return moment(date).format("MMM. DD, YYYY")

    } else if (type == 3) {
      return moment(date).format("YYYY-DD-MM")
    }

    return moment(date).format("MMMM DD, YYYY");


  }


  handleBackgroundOpacity(e) {
    console.log("cominggg ")
    if ((this.fabricCanvas.backgroundColor && this.fabricCanvas.backgroundColor != '#FFFFFF')
      || this.fabricCanvas.backgroundImage) {

      let value = e.target.value * 0.01;

      if (this.fabricCanvas.backgroundType == 'image') {
        this.fabricCanvas.backgroundImage.opacity = value;
        this.updateCanvasState();

      } else {
        const { r, g, b, a } = this.state;
        this.setCanvasColor(r, g, b, value);
      }
      this.fabricCanvas.backgroundOpacity = value;
      this.fabricCanvas.requestRenderAll();

    }
  }


  addDropDownOptionField(e) {
    e.preventDefault();

    const activeObject = this.fabricCanvas.getActiveObject();
    console.log("||| ID ", activeObject)

    if (activeObject.options) {
      activeObject.set({
        options: activeObject.options.concat("")
      });
      this.setState({
        activeObject
      }, () => {
        this.fabricCanvas.requestRenderAll()
      })
    }

  }

  removeDropDownOptionField(index) {
    const activeObject = this.fabricCanvas.getActiveObject()

    if (activeObject.options) {
      activeObject.set({
        options: activeObject.options.filter((option, i) => i !== index)
      })
      this.setState({
        activeObject
      }, () => {
        this.fabricCanvas.requestRenderAll()
      })
    }
  }

  handleDropDownOptionInput(value, index) {
    console.log("Telavi", value, index)
    const { updateCustomClonedFieldDropdown } = this.props;
    const activeObject = this.fabricCanvas.getActiveObject()

    if (activeObject.options) {
      activeObject.set({
        options: activeObject.options.map((option, i) => {
          if (i === index) {
            return value;
          }

          return option;
        })
      })
      this.setState({
        activeObject
      }, () => {
        this.updateCanvasState();
        this.fabricCanvas.requestRenderAll();
        updateCustomClonedFieldDropdown(activeObject.name, activeObject.options)
      })
    }
  }

  updateCustomClonedFieldDropdown(name, value) {
    this.fabricCanvas.forEachObject((object) => {

      if (object.name === name) {
        if (Array.isArray(value)) {
          object.options = value;
        } else {
          console.log('VALISD', value)
          object.selectedOption = value;
          object.value = value;
          object.text = value;
        }
      }
    });
    this.updateCanvasState();
    this.fabricCanvas.requestRenderAll();
  }

  discardActiveObject() {
    this.fabricCanvas.discardActiveObject();
  }

  saveCanvas(e) {
    e.preventDefault();
    this.fabricCanvas.includeDefaultValues = false;
    console.log('this.fabricCanvas', this.fabricCanvas);
    const canvasData = this.fabricCanvas.toJSON([
      "id",
      "isDropdown",
      "fieldType",
      "customField",
      "options",
      "name",
      "template",
      "value",
      "order",
      "field_id"
    ]);
    console.log("()()()())()) ", canvasData);
    // this.updateCanvasState();

    //TODO[@Levan]: Change this to be more sexy
    $(this.props.mainFormId).submit();
  }

  setActiveObjectOptionsOrder(options) {

    this.setState({
      activeObject: {
        ...this.state.activeObject,
        opacity: this.state.activeObject.opacity,
        options
      }
    }, () => {
      console.log("oleee ", this.state.activeObject)
      const activeObject = this.fabricCanvas.getActiveObject();

      activeObject.set({
        options
      })
      this.fabricCanvas.requestRenderAll();
      console.log("After ", activeObject);
      this.updateCanvasState();

    })
  }

  handleCustomFieldCheckbox() {
    const { updateClonedCustomFieldCheckBox } = this.props;
    let activeObject = this.fabricCanvas.getActiveObject();

    if (activeObject.fieldType == "checkbox") {
      this.setState({
        activeObject: {
          ...this.state.activeObject,
          value: !this.state.activeObject.value
        }
      }, () => {
        activeObject.value = !activeObject.value;

        const canvasValue = activeObject.value ? '☑' : '☐';
        activeObject.text = canvasValue;
        activeObject.template = canvasValue;

        this.updateCanvasState();
        this.fabricCanvas.requestRenderAll();
        updateClonedCustomFieldCheckBox(activeObject.name, canvasValue, activeObject.value);
      })
    }
  }

  updateClonedCustomFieldCheckBox(name, canvasValue, value) {
    this.fabricCanvas.forEachObject((object) => {
      console.log("JJJJJ", object)

      if (object.name === name) {
        console.log("JJJJJ")
        console.log("Canvas Name - ", this.props.type);

        object.value = value;
        object.text = canvasValue;
        object.template = canvasValue;
      }
    });
    this.updateCanvasState();
    this.fabricCanvas.requestRenderAll();
  }

  handleCustomTextFieldValue(e) {
    const activeObject = this.fabricCanvas.getActiveObject();
    const value = e.target.value;
    const { updateClonedCustomFieldTextValue } = this.props;

    this.setState({
      activeObject: {
        ...activeObject,
        value,
        text: value
      }
    }, () => {
      activeObject.set({
        value,
        text: value
      })
      this.updateCanvasState();
      this.fabricCanvas.requestRenderAll();
      updateClonedCustomFieldTextValue(activeObject.name, value);
    })
  }

  updateClonedCustomFieldTextValue(name, value) {
    console.log("Canvas Name - ", this.props.type);
    this.fabricCanvas.forEachObject((object) => {
      console.log("JJJJJ", object)

      if (object.name === name) {
        console.log("JJJJJ")
        console.log("Canvas Name - ", this.props.type);

        object.value = value;
        object.text = value;
      }
    });
    this.updateCanvasState();
    this.fabricCanvas.requestRenderAll();
  }


  handleDropDownSelect(e) {
    const { updateCustomClonedFieldDropdown } = this.props;
    const activeObject = this.fabricCanvas.getActiveObject();
    const value = e.target.value;

    this.setState({
      activeObject: {
        ...activeObject,
        value,
        text: value,
        selectedOption: value
      }
    }, () => {
      activeObject.set({
        value,
        text: value,
        selectedOption: value
      })
      this.updateCanvasState();
      this.fabricCanvas.requestRenderAll();
      updateCustomClonedFieldDropdown(activeObject.name, activeObject.selectedOption)

    })
  }


  moveLayerForward() {
    console.log("Layer FOwradd")
    const activeObject = this.fabricCanvas.getActiveObject();

    this.fabricCanvas.bringForward(activeObject);
    // this.fabricCanvas.requestRenderAll();
  }

  moveLayerBackward() {
    console.log("Layer Backward")

    const activeObject = this.fabricCanvas.getActiveObject();

    this.fabricCanvas.sendBackwards(activeObject);
    // this.fabricCanvas.requestRenderAll();
  }

  startImageLoading() {
    this.setState({
      isLoadingImage: true
    })
  }

  stopImageLoading() {
    this.setState({
      isLoadingImage: false
    })
  }

  render() {
    const {
      isInPreviewMode,
      activeObject,
      activeObjectType,
      scale,
      defaultFields,
      canvasState,
      isCustomFieldNameTaken,
      isLoadingImage
    } = this.state

    const {
      handleCustomFieldNameChange,
      customFields,
      setCustomFieldItems,
      populateRealValues,
      canvasJson,
      clonedObjects,
      card_import_data
    } = this.props;

    let undoDisabled = this.fabricCanvas && this.fabricCanvas.getObjects().length <= 1 ? true : false;

    // TODO[@Levan]: Fix {_config.canvasState in hidden input
    let finalCanvasJson = ''
    if (!_.isEmpty(canvasState[canvasState.length - 1])) {
      // console.log("CANVASIT ", JSON.parse(_config.canvasState[_config.canvasState.length - 1]))
      finalCanvasJson = canvasState[canvasState.length - 1]
    } else if (!_.isEmpty(canvasJson)) {
      finalCanvasJson = canvasJson
    } else {
      finalCanvasJson = ''
    }

    // console.log(finalCanvasJson);

    return (
      <div name={this.props.canvasName} className={this.props.type}>

        <input type="hidden" value={finalCanvasJson} onChange={() => console.log("")} name={this.props.canvasName} />
        <div className="canvas-container row">

          <div className="canvas-main">

            {
              isLoadingImage ? (
                <div className="loader spinner">
                  Image is Loading, Please Wait ...
                </div>
              ) : null
            }

            <div className="canvas-wrapper" style={{
              transform: `scale(${scale})`,
            }}>


              <canvas ref={this.props.type} > </canvas>

              {activeObject && (
                <div style={{ margin: '10px auto' }} className="btn-group copy-paste-btns" role="group" aria-label="...">
                  {
                    this.state.copied ? (
                      <button
                        onClick={() => this.paste()}
                        type="button"
                        className="btn btn-default">
                        paste
                      </button>
                    ) : (
                        <button
                          onClick={() => this.copy()}
                          type="button"
                          className="btn btn-default">
                          copy
                        </button>
                      )
                  }
                  <button
                    onClick={() => this.removeActiveElement()}
                    type="button"
                    className="btn btn-default ">
                    <span className="glyphicon glyphicon-trash"></span>
                  </button>
                </div>

              )}

            </div>

            <div className="scale-controls-reset" >
            </div>
          </div>
        </div>


        <div className="basic-controls-wrapper" >

          <BasicControls
            previewCanvas={this.previewCanvas.bind(this)}
            addText={this.addText.bind(this)}
            addLine={this.addLine.bind(this)}
            addRect={this.addBox.bind(this)}
            addImage={this.addImage.bind(this)}
            handleImageUpload={this.handleImageUpload.bind(this)}
            setCanvasColor={this.setCanvasColor.bind(this)}
            color={this.state.backgroundColor}
            activeObject={activeObject}
            removeActiveElement={this.removeActiveElement.bind(this)}
            defaultFields={defaultFieldsList}
            customFields={customFields}
            handleCustomFieldNameChange={handleCustomFieldNameChange}
            addCustomField={this.addCustomField.bind(this)}
            removeCustomFieldItem={this.removeCustomFieldItem.bind(this)}
            addSignature={this.addSignature.bind(this)}
            setCustomFieldItems={setCustomFieldItems}
            updateCanvasState={this.updateCanvasState.bind(this)}
            populateRealValues={populateRealValues}
            addCustomImage={this.addCustomImage.bind(this)}
            isCustomFieldNameTaken={isCustomFieldNameTaken}
            startImageLoading={this.startImageLoading.bind(this)}
            stopImageLoading={this.stopImageLoading.bind(this)}
            addCustomSignature={this.addCustomSignature.bind(this)}

          />
        </div>



        <ControlPanel
          type={activeObjectType}
          activeObject={activeObject}
          changeActiveObjectFill={this.changeActiveObjectFill.bind(this)}
          changeActiveObjectFontSize={this.changeActiveObjectFontSize.bind(this)}
          handleActiveObjectOpacity={this.handleActiveObjectOpacity.bind(this)}
          handleActiveObjectLeft={this.handleActiveObjectLeft.bind(this)}
          handleActiveObjectTop={this.handleActiveObjectTop.bind(this)}
          handleActiveObjectWidth={this.handleActiveObjectWidth.bind(this)}
          handleActiveObjectHeight={this.handleActiveObjectHeight.bind(this)}
          previewCanvas={this.previewCanvas.bind(this)}
          editCanvas={this.editCanvas.bind(this)}
          isInPreviewMode={isInPreviewMode}
          resetCanvas={this.clearCanvas.bind(this)}
          defaultFields={defaultFields}
          alignTextLeft={this.alignTextLeft.bind(this)}
          alignTextCenter={this.alignTextCenter.bind(this)}
          alignTextRight={this.alignTextRight.bind(this)}
          handleTextFontFamily={this.handleTextFontFamily.bind(this)}
          handleTextStyle={this.handleTextStyle.bind(this)}
          copy={this.copy.bind(this)}
          paste={this.paste.bind(this)}
          handleDateFormatChange={this.handleDateFormatChange.bind(this)}
          changePreviewText={this.changePreviewText.bind(this)}
          changePreviewDate={this.changePreviewDate.bind(this)}
          handleActiveObjectStrokeWidth={this.handleActiveObjectStrokeWidth.bind(this)}
          makeTextStyle={this.makeTextStyle.bind(this)}
          saveCanvas={this.saveCanvas.bind(this)}
          addDropDownOptionField={this.addDropDownOptionField.bind(this)}
          removeDropDownOptionField={this.removeDropDownOptionField.bind(this)}
          handleDropDownOptionInput={this.handleDropDownOptionInput.bind(this)}
          setActiveObjectOptionsOrder={this.setActiveObjectOptionsOrder.bind(this)}
          populateRealValues={populateRealValues}
          handleCustomFieldCheckbox={this.handleCustomFieldCheckbox.bind(this)}
          updateImage={this.updateImage.bind(this)}
          handleCustomTextFieldValue={this.handleCustomTextFieldValue.bind(this)}
          handleDropDownSelect={this.handleDropDownSelect.bind(this)}
          moveLayerForward={this.moveLayerForward.bind(this)}
          moveLayerBackward={this.moveLayerBackward.bind(this)}
          customFields={customFields}
          changePreviewForCheckbox={this.changePreviewForCheckbox.bind(this)}
          changePreviewDropDown={this.changePreviewDropDown.bind(this)}
          clonedObjects={clonedObjects}
          changePreviewImage={this.changePreviewImage.bind(this)}
          card_import_data={card_import_data}
        />


        <div className="scale-controls" >
          <div className="scale-controls-left" >

            {
              this.isZoomAtMin() ? (<span className="scale-btn scale-down disabled" > - </span>
              ) : (<span onClick={() => this.scaleDown()}
                className="scale-btn scale-down" > - </span>
                )
            } <span>
              {
                this.renderZoomInPercentage(scale)
              } </span>

            {
              this.isZoomAtMax() ? (<span className="scale-btn scale-up disabled"> + </span>
              ) : (<span onClick={
                () => this.scaleUp()
              }
                className="scale-btn scale-up" > + </span>
                )
            } </div> <div onClick={() => this.resetZoom()}
              className="scale-controls-right" >
            <span className="scale-btn" > reset zoom </span> </div >

        </div>




        <div className="undo-redo-container">

          <IoIosUndo
            style={{ opacity: undoDisabled ? 0.3 : 1 }}
            onClick={() => this.undo(true)} className="btn-icon" />

          <IoIosRedo
            // style={{opacity: redoDisabled ?  0.3 : 1 }}

            onClick={() => this.redo(true)} className="btn-icon" />

        </div>

      </div>
    );
  }
}

export default FabricCanvas;
