import React from "react"
import PropTypes from "prop-types"

import onClickOutside from "react-onclickoutside";

import { SketchPicker } from 'react-color'

import '../TextControls/text_controls.scss'

class ShapeControls extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isColorPickerOpened: false
    }
  }


  handleClickOutside = evt => {
    this.setState({ isColorPickerOpened: false })
  }


  handleColorChange({ hex }) {
    this.props.changeActiveObjectFill(hex)
  }

  handleKeyDown(e) {
    if (e.which == 13) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  showImageUploder() {
    const { activeObject, populateRealValues } = this.props;


    return (activeObject.type == "image" && !activeObject.customField) ||
      (activeObject.type == "image" && populateRealValues);
  }

  getImageForCanvas(e) {
    console.log("Local Image IP")

    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onload = () => {
      if (file) {
        var img = new Image();

        img.src = window.URL.createObjectURL(file);

        img.onload = () => {
          const ratio = img.naturalWidth / img.naturalHeight

          window.URL.revokeObjectURL(img.src);

          this.props.updateImage(reader.result, ratio, img.naturalWidth, img.naturalHeight);

        }
      }
    }



    reader.readAsDataURL(file);

  }

  render() {
    const {
      activeObject,
      changeActiveObjectFill,
      handleActiveObjectOpacity,
      handleActiveObjectLeft,
      handleActiveObjectTop,
      handleActiveObjectStrokeWidth,
      handleActiveObjectWidth,
      handleActiveObjectHeight,
      moveLayerForward,
      moveLayerBackward
    } = this.props;

    let width, height;

    if (activeObject.mainImage) {
      console.log("111111111")

      width = activeObject.width * activeObject.scaleX
      height = activeObject.height * activeObject.scaleY
    } else {
      width = activeObject.width
      height = activeObject.height
      console.log("2222222", height)
    }




    console.log('PPRRRPPSSS ', activeObject.opacity)

    return (
      <div style={{ margin: '10px 0', position: 'relative' }}>

        {
          activeObject.customField && (
            <div className="section section-arrange">
              <div className="section-header">
                <h5 className="section-header-title">Custom Field</h5>
              </div>
              <div className="section-body">
                <div className="section-item">
                  <span className="section-entity">Name: </span>
                  <span className="section-entity m-l-10"> {activeObject.name}</span>
                </div>
              </div>
            </div>
          )
        }

        {
          this.showImageUploder() && (
            <div className="section section-arrange">
              <div className="section-header">
                <h5 className="section-header-title">Image</h5>
              </div>
              <div className="section-body">
                <div className="section-item">
                  <span className="section-entity">Image: </span>
                  <span className="section-item">
                    <input
                      type="file"
                      name="background-image"
                      id="background-image"
                      onChange={this.getImageForCanvas.bind(this)}
                    />
                  </span>
                </div>
              </div>
            </div>
          )
        }


        <div className="section section-arrange">
          <div className="section-header">
            <h5 className="section-header-title">Arrange</h5>
          </div>
          <div className="section-body">

            <div className="section-item">
              <div className="section-entity title">Position</div>
              <div className="section-entity">
                <input
                  className="control-panel-input"
                  type="number"
                  value={activeObject && activeObject.left || 0}
                  onChange={(e) => handleActiveObjectLeft(e.target.value)}
                  onKeyDown={this.handleKeyDown.bind(this)}


                />
                <span>X</span>
              </div>
              <div className="section-entity">
                <input
                  className="control-panel-input"
                  type="number"
                  value={activeObject && activeObject.top || 0}
                  onChange={(e) => handleActiveObjectTop(e.target.value)}
                  onKeyDown={this.handleKeyDown.bind(this)}

                />
                <span>Y</span>
              </div>
            </div>

            <div className="section-item">
              <div className="section-entity title">Size</div>
              <div className="section-entity">
                <input
                  className="control-panel-input"
                  type="number"
                  value={activeObject && Math.floor(width)}
                  onChange={(e) => handleActiveObjectWidth(e.target.value)}
                  // disabled={activeObject.mainImage}
                  style={{
                    backgroundColor: activeObject.mainImage && '#ededed'
                  }}
                  onKeyDown={this.handleKeyDown.bind(this)}

                />
                <span>W</span>
              </div>
              <div className="section-entity">
                <input
                  className="control-panel-input"
                  type="number"
                  value={activeObject && Math.floor(height)}
                  onChange={(e) => handleActiveObjectHeight(e.target.value)}
                  // disabled={activeObject.mainImage}
                  style={{
                    backgroundColor: activeObject.mainImage && '#ededed'
                  }}
                  onKeyDown={this.handleKeyDown.bind(this)}

                />
                <span>H</span>
              </div>

            </div>

            <div className="section-item">
              <div className="section-entity title"> Forward/Backward</div>
              <div style={{ marginLeft: '20px', display: 'flex' }}>
                <div className="section-entity">
                  <button
                    className="btn btn-default"
                    type="button"
                    onClick={() => moveLayerForward()}
                  >
                    <i className="glyphicon glyphicon-chevron-up"></i>
                  </button>
                </div>
                <div className="section-entity m-l-10">
                  <button
                    className="btn btn-default"
                    type="button"
                    onClick={() => moveLayerBackward()}
                  >
                    <i className="glyphicon glyphicon-chevron-down"></i>
                  </button>
                </div>
              </div>
            </div>

            <div className="section-item">
              <div className="section-entity title">Opacity</div>
              <div className="section-entity opacity">
                <input
                  className="control-panel-input opacity"
                  type="number"
                  value={activeObject && activeObject.opacity * 100}
                  step="10"
                  min="0"
                  max="100"
                  onChange={(e) => handleActiveObjectOpacity(e.target.value * 0.01)}
                  onKeyDown={this.handleKeyDown.bind(this)}

                />
                <span>%</span>
              </div>

              <div className="section-entity">
                <input
                  type="range"
                  value={activeObject && activeObject.opacity * 100}
                  min="0"
                  max="100"
                  step="10"
                  onChange={(e) => handleActiveObjectOpacity(e.target.value * 0.01)}
                />
              </div>

            </div>

            {activeObject.type != 'image' && (
              <div className="section-item">
                <div className="section-entity title">Border Size</div>
                <div className="section-entity">
                  <input
                    className="control-panel-input strokewidth"
                    type="number"
                    value={activeObject && Math.round(activeObject.strokeWidth) || 0}
                    onChange={(e) => handleActiveObjectStrokeWidth(e.target.value)}
                    onKeyDown={this.handleKeyDown.bind(this)}

                  />
                  <span>SW</span>
                </div>

              </div>

            )
            }



          </div>
        </div>
        <div className="section section-text">
          <div className="section-header">
            <h5 className="section-header-title">Fill Color</h5>
          </div>

          <div className="section-body">

            <div className="section-item color-picker">
              <div className="section-entity title">Color</div>
              <div className="section-entity">
                <div
                  style={{
                    backgroundColor: activeObject && activeObject.stroke || '#000000',
                    border: '1px solid #e3e3e3',
                    borderRadius: '3px'
                  }}
                  onClick={() => this.setState({ isColorPickerOpened: true })} className="color-picker"></div>
                <span></span>
              </div>
              {
                this.state.isColorPickerOpened && (
                  <div className="color-picker-wrapper">
                    <SketchPicker
                      color={activeObject && activeObject.fill || '#e3e3e3'}
                      onChangeComplete={({ hex }) => changeActiveObjectFill(hex)}
                    />
                  </div>
                )
              }

            </div>




          </div>

        </div>




      </div>
    );
  }
}

export default onClickOutside(ShapeControls)
