import React from "react"
import PropTypes from "prop-types"
import './paragraph_preview_answer.scss'
import {capitalize, iconName} from '../../../helper'
import VoiceToText from '../../voice_to_text/VoiceToText'


class ParagraphPreviewAnswer extends React.Component {

  render () {
    const { title, required, id, answer, type, voiceRecognitionStart, onVoiceRecognitionEnd, 
      onVoiceRecognitionResultEnd, voiceRecognitionStop, voiceRecognitionStarted, 
      voiceRecognitionStopped, handleBasicAnswerInput } = this.props
    const hasError = required && !answer ? 'has-error': ''

    return (
      <div className={`paragraph-preview-answer ${hasError}`}>
        <div className="paragraph-preview-answer-title-section">
          <label className="control-label"> 
            {title} 
            {required && <strong className="red-color">*</strong>}
          </label>
          <div className="voice-to-text-wrapper">
            <VoiceToText 
              id={id} 
              type={type}
              voiceRecognitionStart={voiceRecognitionStart}
              voiceRecognitionStop={voiceRecognitionStop}
              onVoiceRecognitionEnd={onVoiceRecognitionEnd}
              onVoiceRecognitionResultEnd={onVoiceRecognitionResultEnd}
              voiceRecognitionStarted={voiceRecognitionStarted}
              voiceRecognitionStopped={voiceRecognitionStopped}
            />
          </div>
        </div>
        <textarea 
          className="form-control" 
          onChange={e=> {
            return handleBasicAnswerInput(id, e.target.value);
          }} 
          value={answer && answer || ''} 
          rows="8"
        >Enter Text</textarea>
        {hasError && <span className="error-message validation-error"> This Field Can't Be Blank </span> }
      
        </div>
    );
  }
}

export default ParagraphPreviewAnswer
