import React from "react"
import PropTypes from "prop-types"
import BasicPreviewAnswer from './basic_preview_answer/BasicPreviewAnswer'
import RadioPreviewAnswer from './radio_preview_answer/RadioPreviewAnswer'
import CheckBoxPreviewAnswer from './checkbox_preview_answer/CheckBoxPreviewAnswer'
import _ from 'lodash'

import SignatureAnswer from './signature_answer/SignatureAnswer'
import ParagraphPreviewAnswer from './paragraph_preview_answer/ParagraphPreviewAnswer'
import LinearScalePreviewAnswer from "./linear_scale_preview_answer/LinearScalePreviewAnswer";
import SelectPreviewAnswer from './select_preview_answer/SelectPreviewAnswer'
import PhotoPreviewAnswer from "./photo_preview_answer/PhotoPreviewAnswer";
import DefaultPreviewAnswer from "./default_preview_answer/DefaultPreviewAnswer";
import Question from "../form_builder_editor/question/Question";
import QuestionPreview from "./question_preview/QuestionPreview";

class FormBuilderPreview extends React.Component {
  
  isQuestionWithLogicItems(kind) {
    return kind == 'radio' || 
      kind == 'select_dropdown'
  }

  hasToShowSubQuestion(question) {
    const {questions} = this.props
    const parentQuestionId = question.parent_question_id

    const parentQuestion = questions.filter(q=> q.id == parentQuestionId)[0]
    let checkedOption = null
    let checkedOptions = null


    if (parentQuestion.kind == 'linear_scale') {
      checkedOption = parentQuestion.linearScaleData.options.filter(o=> o.checked)[0]

      if (!checkedOption  || checkedOption.logic_items.length < 1 ) return false


    } else if (parentQuestion.kind == 'checkbox') {
      checkedOptions = parentQuestion.options.filter(o=> o.checked)


      if (checkedOptions.length < 1 ) return false
     
    } 
    else {
      checkedOption = parentQuestion.options.filter(o=> o.checked)[0]
      
      if (!checkedOption || checkedOption.logic_items.length < 1) return false
    }


    let isSubQuestionChecked = false

    console.log("ODEDODKEODKOED ", parentQuestion.kind)

    if (parentQuestion.kind == 'checkbox' ) {
      checkedOptions && checkedOptions.map(o=> {
        o.logic_items && o.logic_items.filter(l => {
          if (l.question_id == question.id) {
            isSubQuestionChecked = true 
          }
          return false
        })
      })

    } else {
      console.log("LOOOGLGL ", checkedOption)
      isSubQuestionChecked = checkedOption.logic_items && checkedOption.logic_items.filter(l=> l.question_id == question.id).length > 0
    }


    return isSubQuestionChecked
  }

  render () {
    const { questions, onVoiceRecognitionEnd, voiceRecognitionStart, 
      voiceRecognitionStop, onVoiceRecognitionResultEnd, voiceRecognitionStarted,
      voiceRecognitionStopped, isFormSubmitted, handleBasicAnswerInput, handleRadioBtnChange, handlePreviewSelect , handleCheckboxBtnChange, handleLinearScalePreviewChange} = this.props
    
    if (!questions) return null 

    return (
      <div className="form-builder-preview">
      <div className="panel panel-default">
        <div className="panel-heading ">
          <h3 className="form-builder-editor-title">PREVIEW</h3>
        </div>
        <div className="panel-body">
          {
            questions && questions.map((question, index) => {
              if (!question.title || !question.kind) return

              return (
                
                <div key={index}>
                
                  <QuestionPreview 
                    question={question}
                    index={index}
                    onVoiceRecognitionEnd={onVoiceRecognitionEnd}
                    voiceRecognitionStart={voiceRecognitionStart}
                    voiceRecognitionStop={voiceRecognitionStop}
                    onVoiceRecognitionResultEnd={onVoiceRecognitionResultEnd}
                    voiceRecognitionStarted={voiceRecognitionStarted}
                    voiceRecognitionStopped={voiceRecognitionStopped}
                    isFormSubmitted={isFormSubmitted}
                    handleBasicAnswerInput={handleBasicAnswerInput}
                    handleRadioBtnChange={handleRadioBtnChange}
                    handlePreviewSelect={handlePreviewSelect}
                    handleCheckboxBtnChange={handleCheckboxBtnChange}
                    handleLinearScalePreviewChange={handleLinearScalePreviewChange}
                  />
                  {
                    question.subQuestions && _.compact(question.subQuestions).map((q,i)=> {
              if (!q.title || !q.kind) return

                      return (
                        this.hasToShowSubQuestion(q) && 
                        <QuestionPreview 
                          question={q}
                          index={i}
                          key={i}
                          onVoiceRecognitionEnd={onVoiceRecognitionEnd}
                          voiceRecognitionStart={voiceRecognitionStart}
                          voiceRecognitionStop={voiceRecognitionStop}
                          onVoiceRecognitionResultEnd={onVoiceRecognitionResultEnd}
                          voiceRecognitionStarted={voiceRecognitionStarted}
                          voiceRecognitionStopped={voiceRecognitionStopped}
                          isFormSubmitted={isFormSubmitted}
                          handleBasicAnswerInput={handleBasicAnswerInput}
                          handleRadioBtnChange={handleRadioBtnChange}
                          handlePreviewSelect={handlePreviewSelect}
                          handleCheckboxBtnChange={handleCheckboxBtnChange}
                        />
                      )
                    })
                  }
                </div>
            )
          })
        }
          
        </div>
      </div>
    </div>
    );
  }
}


// question.subQuestions && _.compact(question.subQuestions).map((q,i)=> {
export default FormBuilderPreview
