import React from "react"
import PropTypes from "prop-types"
import './basicPreviewAnswer.scss'
import { capitalize, showRequired } from '../../../helper'
import Question from "../../form_builder_editor/question/Question";
import VoiceToText from '../../voice_to_text/VoiceToText'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";


class BasicPreviewAnswer extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      pictures: [],
      selected: null
    };
    this.onDrop = this.onDrop.bind(this);
    this.handleChange = this.handleChange.bind(this);

  }

  onDrop(pictureFiles, pictureDataURLs) {
    this.setState({
      pictures: this.state.pictures.concat(pictureFiles),
    });
  }

  handleChange(date) {
    this.setState({
      startDate: date
    });
  }


  isTexteble() {
    return this.props.type == 'text'
  }

  isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  renderForm() {

    const { type, answer, id, handleBasicAnswerInput } = this.props
    if (type == 'file') {
      return (
        <input type={type} className="form-control" placeholder={`Enter ${capitalize(type)}`} />
      )
    } else if (type == 'date') {
      return (
        <div style={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative'

        }}>
          <DatePicker
            selected={this.isValidDate(answer) && answer}
            className="form-control datepicker-input"
            onChange={(date) => handleBasicAnswerInput(id, date)}
          />
          <span
            style={{
              position: 'absolute',
              top: 15,
              right: 5,
              pointerEvents: 'none'
            }}
            className="glyphicon glyphicon-calendar">

          </span>
        </div>
      )
    }

    return (
      <input type={type} onChange={(e) => handleBasicAnswerInput(id, e.target.value)} value={answer && answer || ''} className="form-control" placeholder={`Enter ${capitalize(type)}`} />

    )
  }

  render() {
    const { type, title, answer, required, index, onVoiceRecognitionEnd, id,
      voiceRecognitionStart, voiceRecognitionStop, onVoiceRecognitionResultEnd,
      voiceRecognitionStarted, voiceRecognitionStopped, handleBasicAnswerInput } = this.props

    const hasError = required && !answer ? 'has-error' : ''

    return (
      <div className={`basic-preview-answer ${hasError}`} style={{ margin: '20px 0' }}>
        <div className="basic-preview-answer-title-section">
          <label className="control-label">
            {title}
            {required && <strong className="red-color">*</strong>}
          </label>
          <div className="voice-to-text-wrapper">
            {this.isTexteble() && (
              <VoiceToText
                id={id}
                type={type}
                voiceRecognitionStart={voiceRecognitionStart}
                voiceRecognitionStop={voiceRecognitionStop}
                onVoiceRecognitionEnd={onVoiceRecognitionEnd}
                onVoiceRecognitionResultEnd={onVoiceRecognitionResultEnd}
                voiceRecognitionStarted={voiceRecognitionStarted}
                voiceRecognitionStopped={voiceRecognitionStopped}
              />
            )}
          </div>
        </div>
        {
          this.renderForm(type)
        }
        {hasError && <span className="error-message validation-error"> This Field Can't Be Blank </span>}
      </div>
    );
  }
}

export default BasicPreviewAnswer
