import React from "react"
import BasicAnswer from "../basic_answer/BasicAnswer";
import Radio from '../MultipleAnswerItem/MultipleAnswerItem'
import Question from '../question/Question'
import MultipleAnswerItem from "../MultipleAnswerItem/MultipleAnswerItem";

class MultipleAnswer extends React.Component {
  isRadioOrSelect() {
    const {kind} = this.props

    return kind == 'radio' || kind == 'select_dropdown'
  }
  render () {
    const {kind, index, questionId, addMultipleAnswer, options, removeMultipleAnswer, changeMultipleAnswerValue, 
    addLogic, handleAddLogicBtn, showAnswerTemplateModal, subQuestion  } = this.props

    return (
      <div className="radio-answer">
        <BasicAnswer kind={kind} />
        {
          options && options.map((option, i)=> {
            return <MultipleAnswerItem 
              key={i} 
              number={i} 
              optionValue={option.value}
              subQuestion={subQuestion}
              option={option}
              id={option.id}
              questionId={questionId}
              questionIndex={index}
              removeMultipleAnswer={removeMultipleAnswer} 
              changeMultipleAnswerValue={changeMultipleAnswerValue}
              addLogic={addLogic}
              handleAddLogicBtn={handleAddLogicBtn}
            />
          })
        }
        <button onClick={()=> addMultipleAnswer(questionId) } className="btn btn-primary">add answer</button>
        &nbsp;
        {
          this.isRadioOrSelect() && (
            <button onClick={()=> showAnswerTemplateModal(questionId) } className="btn btn-warning">Answer Templates</button>
          )
        }
      </div>
    );
  }
}

export default MultipleAnswer
