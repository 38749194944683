import React from "react"
import PropTypes from "prop-types"

import './checkbox_preview_answer.scss'

class RadioPreviewAnswer extends React.Component {
  isInvalid() {
    const{options} = this.props

    return options && options.filter(option=> option.checked).length < 1
  }
  labelClass(checked) {
    return checked ? 'default-answer-checked': ''
  }
  render () {
    const {options, title, required, type, id, questionIndex, handleCheckboxBtnChange} = this.props
    const hasError = required && this.isInvalid() ? 'has-error': ''

    return (
      <div className={`radio-answer ${hasError}`} style={{margin: '20px 0'}}>
        <label className="control-label">
        {title}
        {required && <strong className="red-color">*</strong>}

        </label>
        <div className="default-preview-answers">
          {
            options.map((option,index)=> {
              
              return (
                <div 
                  key={index}
                  onClick={()=> handleCheckboxBtnChange(questionIndex, index, id)} 
                  className={`default-preview-answer ${this.labelClass(option.checked)}`}
                >
                  {option.value}
                </div>
              )
            })
          }
          </div>
      {hasError && <span className="error-message validation-error"> This Field Can't Be Blank </span> }

    </div>
    );
  }
}

export default RadioPreviewAnswer
