import React from "react"

import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import _ from "lodash";
import "./sortable_custom_field_list.scss";

const SortableItem = SortableElement(({
  field,
  i,
  removeCustomFieldItem,
  addCustomField
}) => {
  return (
    <li
      key={field.id}
      onClick={() => addCustomField(false, field.type, field.name, field.id)}
      className="sortable-list-item"
    >
      <a>
        <span>
          <span className="sort-icon glyphicon glyphicon-align-justify"></span>
          <span className={field.icon}></span>
          {field.name}
        </span>
        <div style={{ marginRight: 10 }}>
          <span className="glyphicon glyphicon-ok check"></span>
          <span
            onClick={(e) => removeCustomFieldItem(e, field.id)}
            className="glyphicon glyphicon-remove remove">
          </span>
        </div>
      </a>
    </li>
  )
});


const SortableList = SortableContainer(({
  items,
  removeCustomFieldItem,
  addCustomField
}) => {
  return (
    <ul style={{
      listStyle: "none",
      paddingLeft: 0
    }}>
      {items.map((value, index) => (
        <SortableItem
          key={`item-${value.id}`}
          index={index}
          i={index}
          field={value}
          removeCustomFieldItem={removeCustomFieldItem}
          addCustomField={addCustomField}
        />
      ))}
    </ul>
  );
});

const CustomFieldList = ({ items, onSortEnd, removeCustomFieldItem, addCustomField }) => {
  const sortedItems = _.sortBy(items, ['order']);

  return (
    <SortableList
      items={sortedItems}
      removeCustomFieldItem={removeCustomFieldItem}
      onSortEnd={onSortEnd}
      addCustomField={addCustomField}
      pressDelay={250}
    />
  )
}

export default CustomFieldList;