import React from "react"
import PropTypes from "prop-types"
import BasicAnswer from "../basic_answer/BasicAnswer";
import {range} from '../../../helper'

import './linear_scale_answer.scss'

class LinearScaleAnswer extends React.Component {

  render () {
    const { linearScaleData, questionId, handleLinearScaleChange, 
      handleLinearScaleLabelChange, handleLinearScaleAddLogicChange, addLogicOnLinearScaleClick } = this.props

    const from = linearScaleData.from.value
    const to = linearScaleData.to.value
    const fromLabel = linearScaleData.from.label
    const toLabel = linearScaleData.to.label

    return (
      <div className="linear-scale-answer">
        <BasicAnswer kind="linear-scale" />
       
        <div className="linear-scale-box">
          <div className="form-inline">
            <div className="form-group">
              <label>From:</label>
              <select 
                className="form-control" 
                onChange={(e)=> handleLinearScaleChange(questionId, 'from', e.target.value)}
                value={from && from || ''}
                >
                {range(0,2).map(n=> <option key={n}>{n}</option>)}
              </select>
            </div>
            <div className="form-group">
              <label>To:</label>
              <select 
                className="form-control" 
                onChange={(e)=> handleLinearScaleChange(questionId, 'to', e.target.value)}
                value={to && to || ''}
                >
                {range(2,11).map(n=> <option key={n}>{n}</option>)}
              </select>
            </div>
          </div>

          <div className="form-horizontal linear-scale-values">
            <div className="form-group">
              <label className="col-sm-1">{from}</label>
              <div className="col-sm-6">
                <input 
                  type="text" 
                  className="form-control" 
                  placeholder="Label (optional)"
                  value={fromLabel}
                  onChange={(e)=> handleLinearScaleLabelChange(questionId, 'from', e.target.value)}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-1">{to}</label>
              <div className="col-sm-6">
                <input 
                  type="text" 
                  className="form-control" 
                  placeholder="Label (optional)"
                  value={toLabel}
                  onChange={(e)=> handleLinearScaleLabelChange(questionId, 'to', e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="col-xs-12 col-sm-10" style={{display: 'flex', alignItems: 'center'}}>
            <div className="col-sm-8">
            <select 
                className="form-control" 
                onChange={(e)=> handleLinearScaleAddLogicChange(questionId, e.target.value)}
                value={linearScaleData.currentLogicItemValue || -1}
                >
                <option disabled value="-1">Please Select From Below</option>
                {range(parseInt(from, 10), parseInt(to, 10)+1).map(n=> <option value={n} key={n}>{n}</option>)}
              </select>
            </div>
            <div className="col-sm-6">
              <span onClick={()=> addLogicOnLinearScaleClick(questionId)} className="label label-primary"> ADD LOGIC </span>
            </div>
          </div>
        
        </div>
      </div>
    );
  }
}

export default LinearScaleAnswer
