import React from "react"
import PropTypes from "prop-types"
import DatePicker from 'react-datepicker'
import _ from 'lodash';

import "react-datepicker/dist/react-datepicker.css";


window.URL = window.URL || window.webkitURL;

class PreviewControls extends React.Component {


  getImageForCanvas(id, e) {
    console.log("Local Image IP", id)
    const { changePreviewImage } = this.props;

    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onload = () => {
      if (file) {
        var img = new Image();

        img.src = window.URL.createObjectURL(file);

        img.onload = () => {
          const ratio = img.naturalWidth / img.naturalHeight

          window.URL.revokeObjectURL(img.src);

          changePreviewImage(reader.result, ratio, img.naturalWidth, img.naturalHeight, id);

        }
      }
    }



    reader.readAsDataURL(file);

  }

  generatePreviewFields() {
    const { defaultFields, clonedObjects } = this.props;

    console.log('cloned fieldssss ', clonedObjects)

    let objects = _.unionBy(clonedObjects, defaultFields, "id");

    return objects;

  }

  renderCheckboxField(index, field, changePreviewForCheckbox) {
    return (
      <div key={index} className="form-group default-field-item">

        <label className="form-label">
          {field.name}
        </label>

        <input
          type="checkbox"
          className="form-control"
          style={{ width: 'auto' }}
          value={field.previewValue}
          onChange={(e) => changePreviewForCheckbox(field.id, e.target.value)}
        />

      </div>
    )
  }


  renderImageField(index, field, changePreviewImage) {
    return (
      <div key={index} className="form-group default-field-item">

        <label className="form-label">
          {field.name}
        </label>

        <input
          type="file"
          name="background-image"
          id="background-image"
          className="form-control"
          onChange={this.getImageForCanvas.bind(this, field.id)}
        />
      </div>
    )
  }

  renderDropDownField(index, field, changePreviewDropDown) {
    console.log("DRP FIELD ", field)
    return (
      <div key={index} className="form-group default-field-item">

        <label className="form-label">
          {field.name}
        </label>

        <select
          defaultValue="0"
          onChange={(e) => changePreviewDropDown(field.id, e.target.value)}
          className="form-control"
        >
          <option value="0" disabled>Select Option</option>
          {
            field.options.map((option, i) => {
              return (
                <option key={i} value={option}>{option}</option>
              )
            })
          }
        </select>

      </div>
    )
  }

  render() {
    const {
      defaultFields,
      changePreviewText,
      changePreviewDate,
      changePreviewForCheckbox,
      changePreviewDropDown
    } = this.props;

    const previewFields = this.generatePreviewFields();

    console.log('previewFields ', previewFields)

    return (
      <form style={{ width: '90%', margin: '10px auto', position: 'relative' }}>

        {

          previewFields.map((field, index) => {
            console.log('fieeeeeee', field.fieldType)
            if (field.isDateField) {
              console.log("accordion")
              return (
                <div key={index} className="form-group default-field-item">
                  <label className="form-label">
                    {field.defaultValue}
                  </label>
                  <div>
                    <DatePicker
                      value={field.value}
                      selected={field.value}
                      onChange={(date) => changePreviewDate(field.id, date)}
                      className="form-control"
                    />
                  </div>
                </div>
              )
            } else if (field.fieldType == "checkbox" || field.type == "checkbox") {
              console.log("checkbox i am")
              return this.renderCheckboxField(index, field, changePreviewForCheckbox)
            } else if (field.fieldType == "dropdown" || field.type == "dropdown") {
              return this.renderDropDownField(index, field, changePreviewDropDown)
            } else if (field.fieldType == "image" || field.type == "image") {
              return this.renderImageField(index, field)
            } else {
              return (
                <div key={index} className="form-group default-field-item">

                  <label className="form-label">
                    {field.defaultValue || field.name}
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    placeholder={`Enter ${field.defaultValue || field.name}`}
                    value={field.previewValue}
                    onChange={(e) => changePreviewText(field.id, e.target.value)}
                  />

                </div>
              )
            }

          })
        }

      </form>
    );
  }
}

export default PreviewControls