import React from "react"
import PropTypes from "prop-types"
import {range, capitalize} from '../../../helper'

import './linear_scale_preview_answer.scss'

class LinearScalePreviewAnswer extends React.Component {

  render () {
    const { type, title, required, linearScaleData, question, handleLinearScalePreviewChange } = this.props
    const from = linearScaleData.from.value
    const to = linearScaleData.to.value
    const fromLabel = linearScaleData.from.label
    const toLabel = linearScaleData.to.label

    return (
      <div className="linear-scale-preview-answer">
        <label> 
        {title} 
        {required && <strong className="red-color">*</strong>}
        </label>
        <div className="linear-scale-container">
          <div className="linear-scale-label">{capitalize(fromLabel)}</div>
          <div className="linear-scale-form">
            {range(parseInt(from, 10), parseInt(to, 10)+1).map(number=> {
              return (
                <div key={number} className="linear-scale-item">
                  <div> {number} </div>
                  <input value={number} onChange={(e)=> handleLinearScalePreviewChange(question.id, e.target.value)} type="radio" name={`linear-scale-${Date.now()}`} />
                </div>
              )
            })}
          </div>
          <div className="linear-scale-label">{capitalize(toLabel)}</div>
        </div>
      </div>
    );
  }
}
<div class="radio">
  
</div>

export default LinearScalePreviewAnswer
