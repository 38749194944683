import React from "react"
import PropTypes from "prop-types"

class SelectPreviewAnswer extends React.Component {
  

  render () {
    const {options, title, required, type, handlePreviewSelect, questionIndex, id} = this.props
    const hasError = required && !answer ? 'has-error': ''
    const answer = options.filter(o=> o.checked)[0]

    return (
      <div className={`radio-answer ${hasError}`} style={{margin: '20px 0'}}>
        <label className="control-label">
        {title}
        {required && <strong className="red-color">*</strong>}

        </label>

        <select 
          className="form-control" 
          value={answer && answer.id || 0} 
          onChange={(e)=> handlePreviewSelect(id, e.target.value)}
        >
          <option disabled value="0">Please Select From Below</option>
          {
            options && options.map((option, i)=> {
              return (
                <option key={i} data-index={i} value={option.id}>{option.value}</option>
              )
            })
          }
      </select>

      {hasError && <span className="error-message validation-error"> This Field Can't Be Blank </span> }
      
    </div>
    );
  }
}

export default SelectPreviewAnswer
