import React from "react"
import PropTypes from "prop-types"
import "./default_answers.scss"

const defaultAnswers = [
  [
    {
     title: 'Compliant',
     label: 'label-success'
    },
    {
      title: 'Non-Compliant',
      label: 'label-danger'
     },
     {
      title: 'N/A',
      label: 'label-default'
     }
    ],
    [
    {
      title: 'Good',
      label: 'label-success'
    },
    {
      title: 'Fair',
      label: 'label-warning'
      },
      {
      title: 'Poor',
      label: 'label-danger'
      },
      {
      title: 'N/A',
      label: 'label-default'
      }
    ],
    [
      {
        title: 'Safe',
        label: 'label-success'
      },
      {
        title: 'At-Risk',
        label: 'label-danger'
        },
        {
        title: 'N/A',
        label: 'label-default'
        }
      ],
      [
        {
          title: 'Pass',
          label: 'label-success'
        },
        {
          title: 'Fail',
          label: 'label-danger'
          },
          {
          title: 'N/A',
          label: 'label-default'
          }
        ],
        [
          {
            title: 'Yes',
            label: 'label-success'
          },
          {
            title: 'No',
            label: 'label-danger'
            },
            {
            title: 'N/A',
            label: 'label-default'
            }
          ],
]


class DefaultAnswers extends React.Component {

  labelClass(index) {
    switch(index) {
      case 0:
        return 'label-success'
      break
      case 1:
        return 'label-warning'
      break
      case 2:
        return 'label-default'
      break
    }
  }


  render () {
    return (
      <div className="default-answers">
        <div className="default-answers-header">
          <span className="default-answers-title">Answer Type:</span>
          <div className="">
            <span className="glyphicon glyphicon-text-height" aria-hidden="true"></span>
            <span className="default-answers-type-text">Default Answers </span>
          </div>
        </div>

        <div className="default-answers-list">
        {
          defaultAnswers.map((defaultAnswersItem, i) => {
            return (
              <div key={i} onClick={()=> handleDefaultAnswerItemClick()} className="default-answer-item-wrapper">
                <div key={i} className="default-answers-item">
                {
                  defaultAnswersItem.map((answer,index)=> {
                    return <div key={index} className={`label ${answer.label}`}> {answer.title} </div>
                  })
                }
              </div>
              <span className="default-answer-checked glyphicon glyphicon-ok"></span>
            </div>
            )

          })
        }
        </div>

      </div>
    );
  }
}

export default DefaultAnswers
